import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getPolicyList, getPolicyDetail } from '../../../utile/slice/boardSlice';

function PolicyInfo() {
  const dispatch = useAppDispatch();
  const [policySeq, setPolicySeq] = useState('');
  const policyList = useAppSelector((state) => state.board.policyList);
  const policyDetail = useAppSelector((state) => state.board.policyDetail);

  const handlePolicySeq = (event) => {
    const selectedPolicySeq = event.target.value;
    setPolicySeq(selectedPolicySeq);
    dispatch(getPolicyDetail(selectedPolicySeq));
  };

  useEffect(() => {
    const fetchPolicyList = async () => {
      const result = await dispatch(getPolicyList());
      if (result.payload && result.payload.policyList?.length > 0) {
        const policies = result.payload.policyList;
        const firstPolicy = policies[0];
        setPolicySeq(firstPolicy.policySeq); // 첫 번째 정책의 policySeq로 초기화
        dispatch(getPolicyDetail(firstPolicy.policySeq)); // 첫 번째 정책 상세 정보 불러오기
      }
    };

    fetchPolicyList();
  }, [dispatch]);

  return (
    <div className="inner replace_page">
      <div className="visual" style={{ paddingBottom: '40px' }}>
        <h2 className="tlt">개인정보 처리방침</h2>
        <div className="form_wrap">
          <div className="input_wrap">
            <div className="select_default">
              <select id="policySeq" className="input_select" value={policySeq} onChange={handlePolicySeq}>
              {policyList.map((policy) => (
                <option key={policy.policySeq} value={policy.policySeq}>
                  {policy.policyTitNm}
                </option>
              ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy ver_wrap">
        {policyDetail && (
          <div dangerouslySetInnerHTML={{ __html: policyDetail.policyCont }} />
        )}
      </div>
    </div>
  );
}

export default PolicyInfo;
