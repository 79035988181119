import React from 'react';
import { useAppDispatch } from '../../.././../store';
import { actHideDepth1LPopUp, actCb1LPopUp } from '../../../../utile/slice/layerPopUpSlice';

function Fc() {
  const dispatch = useAppDispatch();

  const closeAlliPopUp = () => {
    dispatch(actHideDepth1LPopUp());
  };

  return (
    <div className="popup_wrap">
      <div className="dim"></div>
      <div className="pop_l_inner">
        <div className="pop_header">
          <strong className="tlt">제휴 금융사</strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeAlliPopUp}
          ></button>
        </div>
        <div className="pop_cont">
          <div className="affiliate">
            <div className="aff_tlt">
              <strong className="tlt">제휴사 문의</strong>
            </div>
            <ul className="aff_list">
              <li>
                <a href="https://www.shinhancard.com/" target="_blank" className="card_cont">
                  <strong className="name">
                    <i className="ico_40_shinhan"></i>
                    신한카드
                  </strong>
                  <button type="button">
                    <i className="ico_24_link"></i>
                  </button>
                </a>
              </li>
              <li>
                <a href="https://www.hanacapital.co.kr/" target="_blank" className="card_cont">
                  <strong className="name">
                    <i className="ico_40_hana"></i>
                    하나캐피탈
                  </strong>
                  <button type="button">
                    <i className="ico_24_link"></i>
                  </button>
                </a>
              </li>
              <li>
                <a href="https://www.woorifcapital.com/" target="_blank" className="card_cont">
                  <strong className="name">
                    <i className="ico_40_woori"></i>
                    우리금융캐피탈
                  </strong>
                  <button type="button">
                    <i className="ico_24_link"></i>
                  </button>
                </a>
              </li>
              {/* <li>
                <a href="/" target="_blank" className="card_cont">
                  <strong className="name">
                    <i className="ico_40_nonghyb"></i>
                    NH농협캐피탈
                  </strong>
                  <button type="button">
                    <i className="ico_24_link"></i>
                  </button>
                </a>
              </li> */}
              <li>
                <strong className="tlt">제휴리스사</strong>
                <a href="https://www.lotte-autolease.com/main/immediate/632" target="_blank" className="card_cont">
                  <strong className="name">
                    <i className="ico_40_lotte"></i>
                    롯데오토리스
                  </strong>
                  <button type="button">
                    <i className="ico_24_link"></i>
                  </button>
                </a>
                {/* <div class="card_cont tem">
                  <strong class="name">제휴 준비 중입니다</strong>
                </div> */}
              </li>
            </ul>
            <span className="desc">
              정확한 금액 및 상담은 할부금융 제휴금융사, 제휴리스사, 제휴보험사를 통해 직접
              문의하시길 바랍니다.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fc;
