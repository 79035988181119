// components\pages\model\features\T4KBox.js
import React from 'react';
import LazyLoad from 'react-lazyload';
import { Swiper, SwiperSlide } from 'swiper/react';

function T4KBox() {

  return (
    <div className="features_tab">
      <section className="visual_title">
        <div className="visual_tit_img t4k_box">
        </div>
        <div className="inner_box">
          <h2 className="title center" data-trigger>T4K BOX<span>(내장탑차)</span></h2>
          <p className='desc' data-trigger>넓은 적재공간으로 배송에 최적화된 탑차</p>
        </div>
      </section>

      <section className="sectionDiv features_section">
        <div className="inner_box flexBox">
          <div className="title_wrap">
            <h2 className="title" data-trigger>내장탑차 특장점</h2>
            <p data-trigger>눈·비·바람·직사광선 등 외부 환경, 외부 충격 및 도난 위험으로부터 화물을 보호하고 충분한 적재 공간을 제공하는 등 화물 수송에 최적화된
              성능과 효율성을 자랑합니다.</p>
          </div>
          <div className="box_features">
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 특징</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>고강도 재질의 탑차 제작으로 외부 환경으로부터 화물을 안전하게 보호합니다.</li>
                <li>화물실 최대 내측 높이 2,000mm로 큰 부피의 화물도 적재가 가능해 운송비용 절감이 가능합니다.</li>
              </ul>
            </div>
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 분야</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>택배 등 라스트 마일 딜리버리(Last Mile Delivery)</li>
                <li>냉장·냉동이 필요하지 않은 식품류, 공산품류 등의 운송</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionDiv features_slide_section">
        <div className="box_feature_img" data-trigger></div>
        <div className="inner_box">
          <div className="box_features_slider" data-trigger>
            <LazyLoad>
              <Swiper
                className="box_features_swiper"
                slidesPerView="auto"
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide className="section_boxf_slide">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>EPDM 도어 가스켓</h3>
                    <p>날씨와 온도 변화에 낮은 탄력성, 변색 및 부식 방지 효과</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>아연도금 바닥 (옵션)</h3>
                    <p>아연도금 스틸 바닥 1.8T를 적용해 탁월한 내구성 자랑</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>리어 스윙도어 &amp; 사이드 스윙도어</h3>
                    <p>270º 회전(리어) , 180º 회전(사이드)이 가능해 넓은 개방감으로 효율적인 작업 가능</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>알루미늄 압출 리어 스텝</h3>
                    <p>미끄럼 및 부식 방지, 경량화</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className="sectionDiv spec_section">
        <div className='inner_box pdt0'>
          <div className="title_wrap">
            <h2 className="title center" data-trigger>내장탑차 제원 및 가격</h2>
          </div>
          <div className='spec_wrap box_spec'>
            <div className='specImgWrap' data-trigger>
              <div className='specImg1'></div>
              <div className='specImg2'></div>
            </div>
            <div className='specTableWrap table_wrap'>
              <div className='table_scroll_wrap'>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan="2" colSpan="2">구분</th>
                      <th rowSpan="2">최대 적재(kg)</th>
                      <th colSpan="3">차량 제원 (mm)</th>
                      <th colSpan="3">내장탑 제원 (mm)</th>
                      <th rowSpan="2">판매가<br />(단위:천원/VAT포함)</th>
                    </tr>
                    <tr>
                      <th>전장</th>
                      <th>전폭</th>
                      <th>전고</th>
                      <th>전장</th>
                      <th>전폭</th>
                      <th className='borderRight'>전고</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="4">기본</td>
                      <td>저상</td>
                      <td>1,000</td>
                      <td>5,400</td>
                      <td>1,790</td>
                      <td>2,170</td>
                      <td>3,000</td>
                      <td>1,720</td>
                      <td>1,270</td>
                      <td>4,900</td>
                    </tr>
                    <tr>
                      <td>표준</td>
                      <td>1,000</td>
                      <td>5,400</td>
                      <td>1,790</td>
                      <td>2,500</td>
                      <td>3,000</td>
                      <td>1,720</td>
                      <td>1,600</td>
                      <td>5,070</td>
                    </tr>
                    <tr>
                      <td>고상</td>
                      <td>1,000</td>
                      <td>5,400</td>
                      <td>1,790</td>
                      <td>2,700</td>
                      <td>3,000</td>
                      <td>1,720</td>
                      <td>1,800</td>
                      <td>5,240</td>
                    </tr>
                    <tr>
                      <td>하이</td>
                      <td>1,000</td>
                      <td>5,400</td>
                      <td>1,790</td>
                      <td>2,900</td>
                      <td>3,000</td>
                      <td>1,720</td>
                      <td>2,000</td>
                      <td>5,330</td>
                    </tr>
                    <tr>
                      <td rowSpan="4">롱</td>
                      <td>저상</td>
                      <td>1,000</td>
                      <td>5,800</td>
                      <td>1,790</td>
                      <td>2,170</td>
                      <td>3,400</td>
                      <td>1,720</td>
                      <td>1,270</td>
                      <td>5,190</td>
                    </tr>
                    <tr>
                      <td>표준</td>
                      <td>1,000</td>
                      <td>5,800</td>
                      <td>1,790</td>
                      <td>2,500</td>
                      <td>3,400</td>
                      <td>1,720</td>
                      <td>1,600</td>
                      <td>5,360</td>
                    </tr>
                    <tr>
                      <td>고상</td>
                      <td>1,000</td>
                      <td>5,800</td>
                      <td>1,790</td>
                      <td>2,700</td>
                      <td>3,400</td>
                      <td>1,720</td>
                      <td>1,800</td>
                      <td>5,520</td>
                    </tr>
                    <tr>
                      <td>하이</td>
                      <td>1,000</td>
                      <td>5,800</td>
                      <td>1,790</td>
                      <td>2,900</td>
                      <td>3,400</td>
                      <td>1,720</td>
                      <td>2,000</td>
                      <td>5,690</td>
                    </tr>
                    <tr>
                      <td colSpan="10">*사양 : 스윙 도어</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='btmText'>※ 당사는 차량 外 냉동탑차, 내장탑, 윙바디 등 개별 특장 차량을 출시, 제작, 납품하지 않으며 그에 관한 의무를 부담하지 아니합니다.
             상세한 사항은 각 딜러사 및 개별 특장사에 문의 부탁드립니다. 
          </div>
        </div>
      </section>




    </div>
  );
}

export default T4KBox;
