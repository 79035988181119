import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { Base64 } from 'js-base64';
import requests from '../../../../../api/requests';
import { getCustomerDetail } from '../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../common/PaginationBoard';
import { isEmpty } from 'lodash';

import CommentSection from './CommentSection'; // 댓글 컴포넌트 추가

function Page() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const seqNum = useParams();
  const [reqParams, setReqParams] = useState(
    state ? state : {bbsSeq: seqNum.bbsSeq, password: ''}
  );
  const [toModify, setToModify] = useState(false);
  const [bbsPassword, setBbsPassword] = useState('');

  // 팝업을 닫는 함수
  const handleClosePopup = () => {
    setToModify(false); // toModify 상태를 false로 설정하여 팝업을 숨깁니다.
  };
  
  // 팝업을 닫는 함수
  const handleClosePopup2 = () => {
    navigate(-1);
  };

  const dispatch = useAppDispatch();

  const qnaDetail = useAppSelector(state => state.board.customerDetail);
  const resultCode = useAppSelector(state => state.board.resultCode);
  const [isNewPassword, setIsNewPassword] = useState(false);

  useEffect(() => {
    if(isNewPassword && resultCode === "02"){
      alert("비밀번호가 일치하지 않습니다.");
      setIsNewPassword(false);
    }
  }, [isNewPassword]);

  useEffect(() => {
    dispatch({
      type: 'board/resetState',
      payload: { customerDetail: [], resultCode: "" }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomerDetail(reqParams));
  }, [seqNum, state]);

  const goDetail = (seq) => {
    navigate(`/customer/qna/${seq}`);
  }

  const goList = () => {
    navigate('/customer/qna', {state: qnaDetail.currentPageNo});
  }

  const goModify = (pw) => {
    navigate(`/customer/qna/${qnaDetail.bbsSeq}/write`, {
      state: {
        bbsSeq :qnaDetail.bbsSeq, 
        password:pw, 
        mode :'modify'
      }});
  }

  const setPassword = (event) => {
    const parentNode = event.target.parentNode;
    const input = parentNode.querySelector('[type=password]').value;

    if(input === ""){
      alert("비밀번호를 입력해주세요.");
      return false;
    }

    setBbsPassword(input);

    if(resultCode==="01" || resultCode === "02"){
      dispatch(getCustomerDetail({...reqParams, password:input})).then(()=>{
        setIsNewPassword(true);
      });
    }else {
      verifyPassword(input);
    }
  }

  const verifyPassword = (input) => {

    fetch(`${requests.CUSTOMER}/${seqNum.bbsSeq}${requests.CHECK_PASSWORD}`, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          bbsSeq: seqNum.bbsSeq,
          password: input
      })
    })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw Error(`Request rejected with status ${response.status}`);
      }
    })
    .then(res => res.json())
    .then(res => {
      if (res.data.sameYn) {
        if(toModify){
          goModify(input);
        }
      } else{
        alert("비밀번호가 일치하지 않습니다.");
      }
    })
    .catch(err => {
      alert("오류가 발생했습니다. 잠시후 다시 시도해주세요.");
      navigate('/customer/qna');
    });
  }

  return (
    <>
      <div className="board" key={seqNum.bbsSeq}>
        {!isEmpty(qnaDetail) && 
          <>
            <span className="date">{qnaDetail.createDate}</span>
            <strong className="tlt">{qnaDetail.bbsTitNm}</strong>
            <div className="cont">
              <div className="img_wrap">
                <img src={qnaDetail.fileFullPath} alt="" />
              </div>
              <p className="desc editor-desc" dangerouslySetInnerHTML={{ __html: qnaDetail.bbsCont }} />
            </div>            
            <CommentSection key={`${seqNum.bbsSeq}-${bbsPassword}`} bbsSeq={seqNum.bbsSeq} bbsPassword={bbsPassword}/>
            <PaginationBoard detailData={qnaDetail} goDetail={goDetail} goList={goList} />
            <div className='btn_layout right'>
              <button className="btn_primary_m" onClick={() => setToModify(true)}><span>수정하기</span></button>
            </div>
          </>
        }
      </div>
      {(resultCode==="01" || resultCode==="02")&&
        <div className="boardpop_wrap">
          <button type="button" title="팝업 닫기" class="ico_22_pop_close pwPopClose" onClick={handleClosePopup2}></button>
          <div className="conf_inner">
            <div className="tlt">비밀번호 입력</div>
            <input type="password" className="input_wrap"/>
            <input type="button" className="btn_wrap" value="확인" onClick={setPassword}/>
          </div>
        </div>
      }
      {(toModify)&&
        <div className="boardpop_wrap">
          <button type="button" title="팝업 닫기" class="ico_22_pop_close pwPopClose" onClick={handleClosePopup}></button>
          <div className="conf_inner">
            <div className="tlt">비밀번호 입력</div>
            <input type="password" className="input_wrap"/>
            <input type="button" className="btn_wrap" value="확인" onClick={setPassword}/>
          </div>
        </div>
      }
    </>
  );
}

export default Page;
