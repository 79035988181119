import React from 'react';

function BuyguideBenefits() {
  return (
    <div className="buyguide-benefits">
      <div className="cont_box">
        <div className="bg_gray"></div>
        <strong className="tlt">전기트럭 세제 혜택</strong>
        <div className="card_layout">
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">개별소비세</strong>
              <p className="desc">감면 (26년말까지)</p>
            </div>
          </div>
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">교육세</strong>
              <p className="desc">감면 (26년말까지)</p>
            </div>
          </div>
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">연간 자동차세</strong>
              <p className="desc">13만원 (영업용은 26만원)</p>
            </div>
          </div>
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">취득세</strong>
              <p className="desc">차량 가격의 5% (최대 140만원 감면)</p>
            </div>
          </div>
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">채권 매입 비용</strong>
              <p className="desc">도시철도 채권 최대 250만원 감면 (매입 기준)</p>
            </div>
          </div>
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">기타 혜택</strong>
              <p className="desc">
                공영주차장 할인, 혼잡 통행료 면제 등 <br />
                고속도로 하이패스 통행료 50% 할인(2027년까지)
              </p>
              <p className="sub">*한국도로공사 영업소 등록 시</p>
            </div>
          </div>
        </div>
        <ul className="info">
          <li className="sub">※2022년 2월 전기차 보급정책 기준 금액입니다.</li>
          <li className="sub">
            ※ 전기차는 지방세법 제127조(과세표준과 세율)에 따라 그 밖의 승용자동차로 분류되며,
            영업용은 2만원, 비영업용은 지방교육세(30%)가 포함되어 13만원이 부과됩니다.
          </li>
          <li className="sub">
            ※ 본 내용은 지방자치단체 사정에 의해 변동될 수 있습니다. 자세한 내용은 판매센터의
            영업담당자에게 문의 바랍니다.
          </li>
        </ul>
      </div>

      <div className="cont_benefits">
        <strong className="tlt">면세 안내</strong>
        <div className="cont_box">
          <strong className="sub_tlt">차량 구매 시 과세 (판매가에 포함) </strong>
          <p className="desc_box">
            차량판매가 = <br />
            ⓐ공장도 가격 + ⓑ개별소비세(ⓐ의 5%) + <br />
            ⓒ교육세(ⓑ의 30%) +<br /> 부가세(ⓐ+ⓑ+ⓒ의 10%)
          </p>
          <p className="sub">
            ※ 개별소비세는 정부시책에 따라 2021년 1월 1일부로 3.5%로 한시 인하되었습니다.
          </p>
        </div>
        <div className="cont_box">
          <strong className="sub_tlt">개별소비세/교육세 면세</strong>
          <div className="table_wrap th_lf">
            <table>
              <colgroup>
                <col width="20%" />
                <col width="80%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>장애인/국가유공자</th>
                  <td>
                    - 대상 : 장애정도 중증(1~3급), 국가유공자(상이등급 1~7급), 고엽제
                    후유증(경도~고도), 광주민주화운동(1급~14급)
                    <br /> - 적용대상 : 본인명의 또는 주민등록상 동일세대 거주하는 배우자, 직계
                    존/비속, 형제자매, 직계비속의 배우자와 공동명의로 구입(의무보유기간 5년, 1대에
                    한함)
                    <br /> - 면세한도 : 개별소비세 최대 500만원, 교육세 최대 150만원 감면(부가가치세
                    감면 포함 최대 715만원 감면)
                  </td>
                </tr>
                <tr>
                  <th>친환경차</th>
                  <td>
                    - 하이브리드 : 개별소비세 최대 100만원, 교육세 최대 30만원 감면(부가가치세 감면
                    포함 총 143만원 감면)
                    <br /> - 전기차 : 개별소비세 최대 300만원, 교육세 최대 90만원 감면(부가가치세
                    감면 포함 총 429만원 감면)
                    <br /> - 수소전기차 : 개별소비세 최대 400만원, 교육세 최대 120만원
                    감면(부가가치세 감면 포함 총 572만원 감면)
                  </td>
                </tr>
                <tr>
                  <th>렌터카/택시/장애인용 차량</th>
                  <td>
                    - 차량 판매가에 이미 개별소비세/교육세가 감면이 포함되어 있음
                    <br /> - 일반 차량을 렌터카로 출고 시 개별소비세/교육세 감면 됨
                  </td>
                </tr>
                <tr>
                  <th>다자녀 가구</th>
                  <td>-개별소비세 300만원 감면(친환경차 개별소비세 면세와 중복 적용 가능)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="cont_benefits">
        <strong className="tit">취득세 감면</strong>
        <p className="desc">친환경차(취득세 산출금액에서 감면)</p>
        <div className="table_wrap">
          <div className="table_scroll_wrap">
            <table className="txt_center">
              <colgroup>
                <col width="30%" />
                <col width="40%" />
                <col width="30%" />
              </colgroup>
              <caption className="blind">친환경차 취득세 감면 안내표</caption>
              <thead>
                <tr>
                  <th>차종</th>
                  <th>감면한도</th>
                  <th>기타</th>
                </tr>
              </thead>
              <tbody className="txt_center">
                <tr>
                  <td>하이브리드</td>
                  <td>40만원</td>
                  <td>2022년까지</td>
                </tr>
                <tr>
                  <td>전기차</td>
                  <td>140만원</td>
                  <td>2024년까지</td>
                </tr>
                <tr>
                  <td>수소차</td>
                  <td>140만원</td>
                  <td>2024년까지</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p className="desc">다자녀 가구</p>
        <div className="table_wrap">
          <div className="table_scroll_wrap">
            <table className="txt_center">
              <colgroup>
                <col width="30%" />
                <col width="40%" />
                <col width="30%" />
              </colgroup>
              <caption className="blind">친환경차 취득세 감면 안내표</caption>
              <thead>
                <tr>
                  <th>차종</th>
                  <th>감면한도</th>
                  <th>기타</th>
                </tr>
              </thead>
              <tbody className="txt_center">
                <tr>
                  <td>6인 이하 승용차</td>
                  <td>140만원 이하인 경우 면제, 초과 시 140만원 감면 혜택</td>
                  <td>2024년 12월 31일 까지</td>
                </tr>
                <tr>
                  <td>7~10인 이하 승용차</td>
                  <td>취득세 전액 면제(최소 납부세액 적용)</td>
                  <td>2024년 12월 31일 까지</td>
                </tr>
                <tr>
                  <td>15인 이하 승합차</td>
                  <td>취득세 전액 면제(최소 납부세액 적용)</td>
                  <td>2024년 12월 31일 까지</td>
                </tr>
                <tr>
                  <td>1톤 이하 화물자동차</td>
                  <td>취득세 전액 면제(최소 납부세액 적용)</td>
                  <td>2024년 12월 31일 까지</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="cont_benefits">
        <strong className="tit">
          서울시 공영주차장
          <br /> 감면 혜택 안내
        </strong>
        <p className="desc">
          서울시에 설치된 공영주차장(노상 및 노외)에서 전기차 충전 시 감면혜택을 받을 수 있습니다.
        </p>
        <div className="table_wrap">
          <div className="table_scroll_wrap">
            <table className="txt_center">
              <colgroup>
                <col width="25%" />
                <col width="50%" />
                <col width="25%" />
              </colgroup>
              <caption className="blind">친환경차 취득세 감면 안내표</caption>
              <thead>
                <tr>
                  <th>차종</th>
                  <th>1시간 이내</th>
                  <th>1시간 초과시</th>
                </tr>
              </thead>
              <tbody className="txt_center">
                <tr>
                  <td>전기차</td>
                  <td>면제</td>
                  <td>주차요금의 50% 할인</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ul className="info">
          <li className="sub">※2022년 2월 전기차 보급정책 기준 금액입니다.</li>
          <li className="sub">
            ※ 전기차는 지방세법 제127조(과세표준과 세율)에 따라 그 밖의 승용자동차로 분류되며,
            영업용은 2만원, 비영업용은 지방교육세(30%)가 포함되어 13만원이 부과됩니다.
          </li>
          <li className="sub">
            ※ 본 내용은 지방자치단체 사정에 의해 변동될 수 있습니다. 자세한 내용은 판매센터의
            영업담당자에게 문의 바랍니다.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BuyguideBenefits;
