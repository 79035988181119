// 스와이퍼
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

function BuyguideCharge() {
  // // 컨텐츠 스크롤 위한 소스
  // const ref = useRef();
  // const { events } = useDraggable(ref);

  return (
    <div className="buyguide-charge">
      <strong className="tlt">충전카드 안내</strong>
      <p className="desc">
        환경부는 국내 보급성능 평가시험을 완료한 전기차에 대하여 공용 충전기를 간편하게 이용하실 수
        있는 충전카드를 발급하고 있습니다.
      </p>
      <div className="cont_box">
        <strong className="sub_tlt">충전카드 신청/발급 방법</strong>
        <ul className="list_stage scroll_wrap">
          <li className="item_stage">
            <div className="item_img step1"></div>
            <strong className="tlt">
              01. 환경부 회원가입
              <span>(무공해차 통합누리집)</span>
            </strong>
          </li>
          <li className="item_stage">
            <div className="item_img step2"></div>
            <strong className="tlt">02. 회원카드 신청</strong>
          </li>
          <li className="item_stage">
            <div className="item_img step3"></div>
            <strong className="tlt">03. 카드 수령 후 결제카드 등록</strong>
          </li>
        </ul>
      </div>

      <div className="cont_box">
        <div className="bg_navy"></div>
        <div className="tlt_wrap">
          <strong className="sub_tlt">충전카드 발급처</strong>
          <a
            href="https://www.ev.or.kr/evmonitor"
            title="페이지이동"
            className="btn_txt"
            target="_blank"
            rel="noreferrer"
          >
            <span>전기차 충전소 찾기</span>
            <i className="ico_24_texarrow_wh"></i>
          </a>
        </div>
        <div className="scroll_wrap pc_view">
          <div className="scroll_cont2">
            <Swiper
              slidesPerView="auto"
              loop={true}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo ev"></i>
                  <p className="tlt">
                    환경부
                    <span className="tel">1661-0970</span>
                  </p>
                  <a
                    href="https://www.ev.or.kr/portal/main"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo gsc"></i>
                  <p className="tlt">
                    GS차지비
                    <span className="tel">1544-4279</span>
                  </p>
                  <a
                    href="https://www.gschargev.co.kr/home"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo sk"></i>
                  <p className="tlt">
                    SK 일렉링크
                    <span className="tel">1566-1704</span>
                  </p>
                  <a
                    href="https://skelectlink.co.kr"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo chaevi"></i>
                  <p className="tlt">
                    대영 채비
                    <span className="tel">1522-2573</span>
                  </p>
                  <a
                    href="https://chaevi.com/kr"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo happy"></i>
                  <p className="tlt">
                    해피 차저
                    <span className="tel">1522-1782</span>
                  </p>
                  <a
                    href="https://www.happecharger.com"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo power"></i>
                  <p className="tlt">
                    파워큐브코리아
                    <span className="tel">1833-8017</span>
                  </p>
                  <a
                    href="https://www.powercube.co.kr"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo everon"></i>
                  <p className="tlt">
                    에버온
                    <span className="tel">1661-7766</span>
                  </p>
                  <a
                    href="https://www.everon.co.kr"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo jeju"></i>
                  <p className="tlt">
                    제주전기차자동차서비스
                    <span className="tel">1670-2690</span>
                  </p>
                  <a
                    href="http://jejuevservice.com"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo klin"></i>
                  <p className="tlt">
                    클린일렉스
                    <span className="tel">1811-1340</span>
                  </p>
                  <a
                    href="https://kcharger.net"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo manage"></i>
                  <p className="tlt">
                    매니지온
                    <span className="tel">1833-2377</span>
                  </p>
                  <a
                    href="http://www.kodrm.com"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo cus"></i>
                  <p className="tlt">
                    씨어스
                    <span className="tel">032-873-2302</span>
                  </p>
                  <a
                    href="http://www.cus21.co.kr "
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="item">
                  <i className="ico_item_logo ecarplug"></i>
                  <p className="tlt">
                    이카플러그
                    <span className="tel">1833-4577</span>
                  </p>
                  <a
                    href="http://ecarplug.com"
                    title="페이지 이동"
                    className="btn_txt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* mobile */}
        <div className="scroll_wrap mo_view">
          <ul className="scroll_cont2">
            <li className="item">
              <i className="ico_item_logo ev"></i>
              <p className="tlt">
                환경부
                <span className="tel">1661-0970</span>
              </p>
              <a
                href="https://www.ev.or.kr/portal/main"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo gs"></i>
              <p className="tlt">
                GS커넥트
                <span className="tel">1544-4279</span>
              </p>
              <a
                href="https://www.gsconnect.co.kr/"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo sk"></i>
              <p className="tlt">
                SK 일렉링크
                <span className="tel">1566-1704</span>
              </p>
              <a
                href="https://skelectlink.co.kr"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo chaevi"></i>
              <p className="tlt">
                대영 채비
                <span className="tel">1522-2573</span>
              </p>
              <a
                href="https://chaevi.com/kr"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo happy"></i>
              <p className="tlt">
                해피 차저
                <span className="tel">1522-1782</span>
              </p>
              <a
                href="https://www.happecharger.com"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo power"></i>
              <p className="tlt">
                파워큐브코리아
                <span className="tel">1833-8017</span>
              </p>
              <a
                href="https://www.powercube.co.kr"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo everon"></i>
              <p className="tlt">
                에버온
                <span className="tel">1661-7766</span>
              </p>
              <a
                href="https://www.everon.co.kr"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo jeju"></i>
              <p className="tlt">
                제주전기차자동차서비스
                <span className="tel">1670-2690</span>
              </p>
              <a
                href="http://jejuevservice.com"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo klin"></i>
              <p className="tlt">
                클린일렉스
                <span className="tel">1811-1340</span>
              </p>
              <a
                href="https://kcharger.net"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo manage"></i>
              <p className="tlt">
                매니지온
                <span className="tel">1833-2377</span>
              </p>
              <a
                href="http://www.kodrm.com"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo cus"></i>
              <p className="tlt">
                씨어스
                <span className="tel">032-873-2302</span>
              </p>
              <a
                href="http://www.cus21.co.kr "
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
            <li className="item">
              <i className="ico_item_logo ecarplug"></i>
              <p className="tlt">
                이카플러그
                <span className="tel">1833-4577</span>
              </p>
              <a
                href="http://ecarplug.com"
                title="페이지 이동"
                className="btn_txt"
                target="_blank"
                rel="noreferrer"
              >
                바로가기
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BuyguideCharge;
