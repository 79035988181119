import React from 'react';
import PcImg from '../../../../assets/images/img/img_contact.jpg';
import MoImg from '../../../../assets/images/img/mo_img_contact.jpg';
import RelationPage from '../../../common/RelationPage';
import { Link } from 'react-router-dom';

function Contact() {
  const onClickEmail = (e) => {
    const copyTxt = e.target.innerText;
    window.navigator.clipboard.writeText(copyTxt).then(() => {});
  };

  return (
    <div className="contact">
      <div className="visual inner">
        <h2 className="tlt">문의하기</h2>
      </div>
      <div className="tab-head outinner">
        <div className="tab-inner">
          <button type="button" className="active" onClick="" >
            문의하기
          </button>
          <button type="button">
            <Link to="/customer/faq" title="FAQ">FAQ</Link>
          </button>
          <button type="button" style={{ display: 'none' }}>
            <Link to="/customer/qna" title="고객 게시판">고객 게시판</Link>
          </button>
        </div>
      </div>
      <div className="inner">
        <div className="txt_wrap">
          <span className="sub">어떤 도움이 필요하신가요?</span>
          <strong className="tlt">
            T4K 차량 및 구매 <br className="mo_view" />
            관련해서 <br className="pc_view" />
            언제든 <br className="mo_view" />
            문의해 주세요.
          </strong>
        </div>
        <div className="img_wrap">
          <img src={PcImg} alt="" className="pc_view" />
          <img src={MoImg} alt="" className="mo_view" />
        </div>
        <ul className="contact_list">
          <li className="list">
            <strong className="tlt">구매 상담</strong>
            <p className="desc">구매 및 차량정보 관련 문의가 있으신가요?</p>
            <a href="tel:1600-8251" className="btn_txt_bk tel">
              <i className="ico_20_tel"></i>
              <span>1600-8251</span>
            </a>
            <a href="mailto:gsgmobility@gsgcorp.com" className="btn_txt_bk" onClick={onClickEmail}>
              <i className="ico_20_sendmail"></i>
              <span>gsgmobility@gsgcorp.com</span>
            </a>
          </li>
          <li className="list" style={{ display: 'none' }}>
            <strong className="tlt">정비 상담</strong>
            <p className="desc">정비 예약 및 기기 사용법 관련 상담이 필요하신가요?</p>
            <a href="tel:1600-8251" className="btn_txt_bk tel">
              <i className="ico_20_tel"></i>
              <span>1600-8251</span>
            </a>
            <a href="mailto:t4kcs01@gsgcorp.com" className="btn_txt_bk" onClick={onClickEmail}>
              <i className="ico_20_sendmail"></i>
              <span>t4kcs01@gsgcorp.com</span>
            </a>
          </li>
          <li className="list">
            <strong className="tlt">일반・기타 상담</strong>
            <p className="desc">각종 이벤트, 광고, 홈페이지 관련 문의가 있으신가요?</p>
            <a href="tel:1600-8251" className="btn_txt_bk tel">
              <i className="ico_20_tel"></i>
              <span>1600-8251</span>
            </a>
            <a href="mailto:t4kcs01@gsgcorp.com" className="btn_txt_bk" onClick={onClickEmail}>
              <i className="ico_20_sendmail"></i>
              <span>t4kcs01@gsgcorp.com</span>
            </a>
          </li>
        </ul>
      </div>
      <RelationPage />
    </div>
  );
}

export default Contact;
