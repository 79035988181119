import { useNavigate } from 'react-router-dom';
import { useDraggable } from 'react-use-draggable-scroll';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setScroll } from '../../../../utile/ScrollToTop';
import { actShowDepth1LPopUp } from '../../../../utile/slice/layerPopUpSlice';
import PopUp2 from '../guide/PopupAlli';
import PopUp1 from '../guide/PopupFc';

// 스와이퍼
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

function BuyguideInfor({ tabClickHandler }) {
  const bladeSlideRef = useRef(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);
  const openDetailedItem = (LpopUpName) => {
    dispatch(actShowDepth1LPopUp(LpopUpName));
  };

  const onClickTab = () => {
    tabClickHandler(1);
  };

  const onClickModel = () => {
    dispatch(setScroll(window.scrollY));
    navigate('/model/introduce');
  };

  const onClickBuy = () => {
    dispatch(setScroll(window.scrollY));
    navigate('/buy/reservation');
  };

  const onClickService = () => {
    dispatch(setScroll(window.scrollY));
    navigate('/service/network');
  };

  return (
    <div className="buyguide-information">
      <strong className="tlt">전기트럭 구매 안내</strong>
      <p className="desc">
        대기환경 문제 개선을 위해 정부와 지방자치단체에서 전기차 보급 사업을 추진 하고 있습니다.
        공모를 통해 대상자로 선정될 경우 전기차 구매보조금을 지원받아 구입이 가능하며, 다양한
        세제감면 혜택지원을 받으실 수 있습니다. 구매보조금, 충전기 보조금, 세금 혜택 등 다양한
        혜택과 합리적인 방법으로 전기트럭을 구매하실 수 있습니다.
      </p>

      <div className="cont_information">
        {/* <div className="tlt_wrap"> */}
        <strong className="tit">구매 절차</strong>
        {/* <div className="btn_wrap pc_view">
            <button type="button" title="이전으로" className="btn_box prev"></button>
            <button type="button" title="다음으로" className="btn_box next"></button>
          </div> */}
        {/* </div> */}
        <div className="scroll_wrap pc_view">
          <Swiper
            className="scroll_cont1"
            slidesPerView="auto"
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            <SwiperSlide className="item">
              <div className="item_img step1"></div>
              <div className="tex_wrap">
                <strong className="tlt">01. 차량정보 확인</strong>
                <p className="desc">
                  고객님이 원하시는 T4K에 대한
                  <br /> 기본사양 및 옵션 정보를 확인합니다.
                </p>
                <button onClick={onClickModel} title="페이지이동" className="btn_txt">
                  <span>T4K 모델 보기</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step2"></div>
              <div className="tex_wrap">
                <strong className="tlt">02. 견적 상담 받기</strong>
                <p className="desc">
                  온라인에서 견적이나 카탈로그, 구매상담 등을
                  <br /> 이용하실 수 있습니다.
                </p>
                <button onClick={onClickBuy} title="페이지이동" className="btn_txt">
                  <span>상담하기</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step3"></div>
              <div className="tex_wrap">
                <strong className="tlt">03. 계약서 작성</strong>
                <p className="desc">
                  신분증과 계약금을 준비하시고 판매센터에 방문
                  <br /> 또는 연락하여 전자계약서를 작성합니다.
                </p>
                <button onClick={onClickService} title="페이지이동" className="btn_txt">
                  <span>판매센터 확인</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step4"></div>
              <div className="tex_wrap">
                <strong className="tlt">04. 차량 인도금 완납</strong>
                <p className="desc">
                  선택하신 결제 방법에 따라
                  <br /> 대금을 납부하시면 됩니다.
                </p>
                <button
                  type="button"
                  className="btn_txt"
                  onClick={() => openDetailedItem('popup1')}
                >
                  <span>제휴사 확인</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step5"></div>
              <div className="tex_wrap">
                <strong className="tlt">05. 보험료 납부</strong>
                <p className="desc">
                  원하시는 1톤 트럭보험을 알아보신 후,
                  <br /> 영수증을 발급받으시면 됩니다.
                </p>
                <button
                  type="button"
                  className="btn_txt"
                  onClick={() => openDetailedItem('popup2')}
                >
                  <span>제휴 보험사 확인</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step6"></div>
              <div className="tex_wrap">
                <strong className="tlt">06. 차량 등록</strong>
                <p className="desc">
                  T4K 등록을 위하여 필요한 절차를
                  <br /> 확인하시기 바랍니다.
                </p>
                <button onClick={onClickTab} title="페이지이동" className="btn_txt">
                  <span>T4K 등록절차</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* mobile */}
        <div className="scroll_wrap mo_view">
          <ul className="scroll_cont1">
            <li className="item">
              <div className="item_img step1"></div>
              <div className="tex_wrap">
                <strong className="tlt">01. 차량정보 확인</strong>
                <p className="desc">
                  고객님이 원하시는 T4K에 대한
                  <br /> 기본사양 및 옵션 정보를 확인합니다.
                </p>
                <button onClick={onClickModel} title="페이지이동" className="btn_txt">
                  <span>T4K 모델 보기</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </li>
            <li className="item">
              <div className="item_img step2"></div>
              <div className="tex_wrap">
                <strong className="tlt">02. 견적서 내차 만들기</strong>
                <p className="desc">
                  온라인에서 견적이나 카탈로그, 구매상담 등을
                  <br /> 이용하실 수 있습니다.
                </p>
                <button onClick={onClickBuy} title="페이지이동" className="btn_txt">
                  <span>견적내기</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </li>
            <li className="item">
              <div className="item_img step3"></div>
              <div className="tex_wrap">
                <strong className="tlt">03. 계약서 작성</strong>
                <p className="desc">
                  신분증과 계약금을 준비하시고 판매센터에 방문
                  <br /> 또는 연락하여 전자계약서를 작성합니다.
                </p>
                <button onClick={onClickService} title="페이지이동" className="btn_txt">
                  <span>판매센터 확인</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </li>
            <li className="item">
              <div className="item_img step4"></div>
              <div className="tex_wrap">
                <strong className="tlt">04. 차량 인도금 완납</strong>
                <p className="desc">
                  선택하신 결제 방법에 따라
                  <br /> 대금을 납부하시면 됩니다.
                </p>
                <button
                  type="button"
                  className="btn_txt"
                  onClick={() => openDetailedItem('popup1')}
                >
                  <span>제휴 금융사 확인</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </li>
            <li className="item">
              <div className="item_img step5"></div>
              <div className="tex_wrap">
                <strong className="tlt">05. 보험료 납부</strong>
                <p className="desc">
                  원하시는 1톤 트럭보험을 알아보신 후,
                  <br /> 영수증을 발급받으시면 됩니다.
                </p>
                <button
                  type="button"
                  className="btn_txt"
                  onClick={() => openDetailedItem('popup2')}
                >
                  <span>제휴 보험사 확인</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </li>
            <li className="item">
              <div className="item_img step6"></div>
              <div className="tex_wrap">
                <strong className="tlt">06. 차량 등록</strong>
                <p className="desc">
                  T4K 등록을 위하여 필요한 절차를
                  <br /> 확인하시기 바랍니다.
                </p>
                <button onClick={onClickTab} title="페이지이동" className="btn_txt">
                  <span>T4K 등록절차</span>
                  <i className="ico_24_texarrow"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <ul className="info">
          <li className="sub">
            ※ 정부 정책이나 지방자치단체 사정에 의해 변동될 수 있습니다. 자세한 내용은 해당
            판매센터의 영업담당자에게 확인하시기 바랍니다.
          </li>
        </ul>
      </div>

      <div className="cont_information">
        <div className="bg_gray"></div>
        <strong className="tlt">보조금 지원 안내</strong>
        <p className="desc">
          전기차는 정부/지자체에서 지원하는 구매 보조금, 세금감면 혜택 지원으로 동급 내연기관 차량과
          비슷한 가격에 구매할 수 있습니다. 구매자는 차량 구매대금과 보조금의 차액을 자동차
          제작/수입사에 납부하고, 자동차 제작/수입사는 지방자치단체(국비+지방비보조금)로부터
          보조금을 수령합니다.
        </p>
        <div className="cont_box">
          <strong className="tit">보조금 신청절차</strong>
          <ul className="box_diagram">
            <li className="diagram_item">
              <p className="num">01</p>
              <p className="tlt">
                보조금사업 <br />
                공고
              </p>
            </li>
            <li className="diagram_item">
              <p className="num">02</p>
              <p className="tlt">전기트럭 구매예약</p>
            </li>
            <li className="diagram_item">
              <p className="num">03</p>
              <p className="tlt">구매지원 신청서 접수</p>
            </li>
            <li className="diagram_item">
              <p className="num">04</p>
              <p className="tlt">구매지원대상자 선정/통보</p>
            </li>
            <li className="diagram_item">
              <p className="num">05</p>
              <p className="tlt">
                차량 출고 및 등록
                <span className="sub">(2개월이내)</span>
              </p>
            </li>
            <li className="diagram_item">
              <p className="num">06</p>
              <p className="tlt">
                보조금 신청접수
                <span className="sub">(출고등록10일이내)</span>
              </p>
            </li>
            <li className="diagram_item">
              <p className="num">07</p>
              <p className="tlt">
                보조금 지급
                <span className="sub">(14일이내 원칙)</span>
              </p>
            </li>
          </ul>
          <ul className="info">
            <li className="sub">※ 보조금 신청 절차는 민간부분에 대한 절차 안내입니다.</li>
            <li className="sub">
              ※ 세부사항은 지방자치단체 사정에 의해 변동될 수 있습니다. 자세한 내용은 판매센터의
              영업담당자 또는 보조금 신청 지방자치단체 담당에게 문의 바랍니다.
            </li>
            <li className="sub">
              ※ 보조금 신청접수는 동일 개인에게 2년(의무운행기간)내 보조금 중복지원이 제한됩니다.
            </li>
          </ul>
        </div>
        <div className="cont_box">
          <strong className="tit">접수 시 구비할 서류 안내</strong>
          <p className="desc">구매 지원신청서 접수 시 구매희망자 별 구비서류를 안내해 드립니다.</p>
          <div className="card_layout">
            <div className="card_cont3">
              <div className="cont_l">
                <strong className="tlt">개인</strong>
                <p className="desc">구매지원신청서, 주민등록등본 또는 초본, 차량구매계약서</p>
              </div>
            </div>
            <div className="card_cont3">
              <div className="cont_l">
                <strong className="tlt">개인사업자</strong>
                <p className="desc">
                  구매지원신청서, 주민등록등본 또는 초본, 사업자등록증, 차량구매 계약서
                </p>
              </div>
            </div>
            <div className="card_cont3">
              <div className="cont_l">
                <strong className="tlt">법인사업자</strong>
                <p className="desc">구매지원신청서, 사업자등록증 또는 등기부등본, 차량구매계약서</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cont_information">
        <strong className="tlt">전기차 지원처 안내</strong>
        <div className="cont_box">
          <strong className="tit">지방자치단체별 구매 보조금 안내</strong>
          <p className="desc">
            지방자치단체별 구매보조금 지원금액 및 지급현황은 저공해차 통합누리집을 통해 확인
            가능합니다.
          </p>
          <div className="card_wrap">
            <a
              href="https://www.ev.or.kr/portal/localInfo"
              target="_blank"
              title="페이지이동"
              className="card_item"
              rel="noreferrer"
            >
              <span className="card_tit">보조금 잔여현황 바로가기</span>
              <i className="ico_24_move"></i>
            </a>
            <div className="card_item">
              <span className="card_tit">환경부 통합콜센터</span>
              <span>02-1661-0970</span>
            </div>
          </div>
          <ul className="info">
            <li className="sub">
              ※ 구매 보조금은 실시간 현황과 상이할 수 있으므로, 자세한 사항은 판매센터 또는 각
              지방자치단체 담당자에게 확인하시기 바랍니다.
            </li>
          </ul>
        </div>
      </div>
      {depth1LPopUpName === 'popup1' && <PopUp1 />}
      {depth1LPopUpName === 'popup2' && <PopUp2 />}
    </div>
  );
}

export default BuyguideInfor;
