// components\pages\model\features\Index.js
import { useEffect, useState } from 'react';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import T4KCold from './T4KCold';
import T4KBox from './T4KBox';
import T4KEtc from './T4KEtc';

gsap.registerPlugin(ScrollTrigger);

const TAB_CONTENT = [
  { tabList: "T4K 냉동탑차", tabCont: <T4KCold /> },
  { tabList: "T4K 내장탑차", tabCont: <T4KBox /> },
  { tabList: "기타 특장차", tabCont: <T4KEtc /> },
];

function Features() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleGsap = () => {
      const targets = document.querySelectorAll('[data-trigger]');
      if (!targets.length) return;
      
      ScrollTrigger.defaults({
        end: 'center 55%',
      });

      const createAnimation = (element, startPosition, duration) => {
        return gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: startPosition,
          },
        }).fromTo(
          element,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration }
        );
      };

      ScrollTrigger.matchMedia({
        '(min-width: 769px)': () => {
          targets.forEach(el => createAnimation(el, 'bottom bottom', 1.4));
        },
        // Mobile
        '(max-width: 768px)': () => {
          targets.forEach(el => createAnimation(el, 'center bottom', 0.8));
        }
      });
    };

    handleGsap();

    // Cleanup
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [activeIndex]);

  return (
    <div className="features_wrap">
      <Visual tlt="T4K SPECIAL VEHICLE" sub={''} />
      
      {/* Tab Buttons */}
      <div className="tab-head">
        <div className="tab-inner">
          {TAB_CONTENT.map((section, index) => (
            <button
              key={index}
              className={index === activeIndex ? 'active' : ''}
              onClick={() => setActiveIndex(index)}
            >
              {section.tabList}
            </button>
          ))}
        </div>
      </div>

      {/* Tab Content */}
      <div className="with-tab">
        {TAB_CONTENT[activeIndex].tabCont}
      </div>

      <RelationPage />
    </div>
  );
}

export default Features;