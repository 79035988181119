import React from 'react';
import { Link } from 'react-router-dom';
import QnaList from './List';

function Qna() {
  return (
    <div className="qna">
      <div className="visual inner">
        <h2 className="tlt">문의하기</h2>
      </div>
      <div className="tab-head outinner">
        <div className="tab-inner">
          <button type="button">
            <Link to="/customer/contact" title="문의하기">문의하기</Link>
          </button>
          <button type="button" style={{ display: 'none' }}>
            <Link to="/customer/faq" title="FAQ">FAQ</Link>
          </button>
          <button type="button" className="active" onClick="" >
            고객 게시판
          </button>
        </div>
      </div>
        <QnaList/>
    </div>
  );
}

export default Qna;
