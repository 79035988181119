import { useState } from 'react';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';

import PartsLogistics from './PartsLogistics'; 
import PartsPrice from './PartsPrice'; 

function PartInfo() {
 
  const [activeIndex, setActiveIndex] = useState(0);

  const tabContArr = [
    { tabList: "Parts & Logistics", tabCont: <PartsLogistics /> },
    { tabList: "부품가격조회", tabCont: <PartsPrice /> },
  ];

  return (
    <div className="part">
      <Visual tlt="부품정보" sub={'차량의 부품 정보를 제공합니다.  \n부품을 조회해 보세요.'} />

      {/* 탭 버튼 */}
      <div className="tab-head">
        <div className="tab-inner">
          {tabContArr.map((section, index) => (
            <button
              key={index}
              className={index === activeIndex ? 'active' : ''}
              onClick={() => setActiveIndex(index)}
            >
              {section.tabList}
            </button>
          ))}
        </div>
      </div>

      {/* 탭 콘텐츠 */}
      <div className="inner with-tab">{tabContArr[activeIndex].tabCont}</div>

      <RelationPage />
    </div>
  );
}

export default PartInfo;
