import React from 'react';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';
import { Link } from 'react-router-dom';

function Faq() {
  function tab(classfi) {
    const category = document.querySelectorAll('[data-value]');
    const contain = document.querySelectorAll('[data-contain]');

    category.forEach((e) => {
      e.classList.remove('active');
      classfi.target.classList.add('active');
    });
    contain.forEach((item) => {
      item.classList.remove('active');

      if (classfi.target.dataset.value === 'all') {
        item.classList.add('active');
        return;
      }

      if (classfi.target.dataset.value === item.dataset.contain) {
        item.classList.add('active');
      }
    });
  }

  function accoOpen(e) {
    const target = e.target.closest('.list');
    const list = document.querySelectorAll('.list');

    if (target.classList.contains('active')) {
      target.classList.remove('active');
    } else {
      target.classList.add('active');
    }

    list.forEach((li) => {
      if (target !== li) {
        li.classList.remove('active');
      }
    });
  }
  return (
    <div className="faq">
      <Visual tlt="FAQ" sub={'자주 묻는 질문과 답변을  \n쉽고 빠르게 확인하세요.'} />      
      <div className="tab-head outinner">
        <div className="tab-inner">
          <button type="button">
            <Link to="/customer/contact" title="문의하기">문의하기</Link>
          </button>          
          <button type="button" className="active" onClick="" >
            FAQ
          </button>
          <button type="button" style={{ display: 'none' }}>
            <Link to="/customer/qna" title="고객 게시판">고객 게시판</Link>
          </button>
        </div>
      </div>
      <div className="tab-head type01">
        <div className="tab-inner">
          <button type="button" className="active" onClick={tab} data-value="all">
            전체
          </button>
          {/* <button type="button" className="" onClick={tab} data-value="top10">
            TOP10
          </button> */}
          <button type="button" className="" onClick={tab} data-value="buy">
            구매
          </button>
          <button type="button" className="" onClick={tab} data-value="fix">
            차량
          </button>
          <button type="button" className="" onClick={tab} data-value="homepage">
            홈페이지
          </button>
          <button type="button" className="" onClick={tab} data-value="etc">
            기타
          </button>
        </div>
      </div>
      <div className="inner">
        {/* <div data-contain="top10">
          <ul className="list_wrap4">
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">FAQ 타이틀이 들어갑니다.</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                차량 내부와 트렁크를 청결히 하시고, 차량 외관도 깨끗하게 세차 합니다. 이 때, 차량
                외관의 작은 상처(기스)는 수리 후에 판매 하시는 것이 좋습니다. 또한 구매 후에
                장착하신 옵션 등의 부품은 메이커 순정품일 경우, 가치 평가를 받으 실 때 유리하게
                작용합니다. 이밖에 고객님이 차를 보유하시던 중에 특별히 신경써서 관리 하신 부분,
                예를 들어 소모품의 정기적 교환, 차계부 정리, 비흡연, 시내 출퇴근용으로만 사용 등의
                내 차만의 장점을 말씀해주시면 차량의 가치가 더욱 올라갑니다. 이상입니다. 차량 내부와
                트렁크를 청결히 하시고, 차량 외관도 깨끗하게 세차 합니다. 이 때, 차량 외관의 작은
                상처(기스)는 수리 후에 판매 하시는 것이 좋습니다. 또한 구매 후에 장착하신 옵션 등의
                부품은 메이커 순정품일 경우, 가치 평가를 받으 실 때 유리하게 작용합니다. 이밖에
                고객님이 차를 보유하시던 중에 특별히 신경써서 관리 하신 부분, 예를 들어 소모품의
                정기적 교환, 차계부 정리, 비흡연, 시내 출퇴근용으로만 사용 등의 내 차만의 장점을
                말씀해주시면 차량의 가치가 더욱 올라갑니다. 이상입니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">
                  FAQ 타이틀이 들어갑니다. 파트너사가 아닌 다른 은행/금융기관을 통해 T4K 차량을
                  구매한 경우 납부 방법이 달라지는지 궁금합니다. FAQ 타이틀이 들어갑니다. 파트너사가
                  아닌 다른 은행/금융기관을 통해 T4K 차량을 구매한 경우 납부 방법이 달라지는지
                  궁금합니다.
                </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">내용입니다.</div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">FAQ 타이틀이 들어갑니다.</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">내용입니다.</div>
            </li>
          </ul>
        </div> */}
        <div data-contain="buy" className="active">
          <ul className="list_wrap4">
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 구매 상담은 어떻게 할 수 있나요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                구매 상담은 “카카오T“앱을 통해 가능합니다. “카카오T”앱으로 이동 후 T4K 구매,
                상담신청하기 접수를 하시면 해당 지역 영업담당자를 통해서 상담이 가능합니다. 추후, 본
                사이트에서도 구매 상담 접수가 가능하도록 오픈 예정입니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 직접 실물을 보고 싶은데 매장은 어디에 있나요?</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                판매/서비스 네트워트를 통해 매장 위치 확인하실 수 있습니다. 서울 용산, 구로 및
                경기도 오산, 대구, 부산에 판매 센터 운영 됩니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 보조금 금액은 얼마나 되나요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                보조금은 국내 타전기트럭과 같은 금액의 보조금을 받으실 수 있습니다. 보다 자세한
                사항은 무공해통합누리집 사이트 (https://ev.or.kr) 에서 확인하실 수 있습니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 보조금 자격 요건의 별도로 있나요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                만 18세 이상, 2년내 전기화물차 보조금 수령 이력이 없어야 하며, 보조금을 지급하는
                지자체에 의무기간 이상 거주해야 합니다. 또한 취약계층 (장애인, 차상위 이하 계층,
                상이/독립 유공자 소상공인 등)의 경우 보조금 우선순위를 받을 수 있고, 지자체에 따라
                추가 보조금을 받을 수 있습니다.
              </div>
            </li>
          </ul>
        </div>

        <div data-contain="fix" className="active">
          <ul className="list_wrap4">
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">
                  Q. 견적 산출 중 풀옵션 패키지 외엔 선택 불가 한가요?
                </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                T4K가 런칭되는 2023년은 '풀옵션 패키지'만 선택이 가능합니다. 패키지 품목 내 일부
                품목의 가감 또한 불가하며 패키지에 따른 25만원 할인이 자동 적용 됩니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 차량 외장 컬러에 따른 비용 차이가 있나요?</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                Roland Silver를 선택하시는 경우, Iceberg White 보다 15만원 추가가 됩니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 보증 기간 기준을 어떻게 되나요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                보증수리 기간은 신차 판매일부터 적용되며, 기간 및 주행거리 중 선 도래한 항목이
                보증기간 만료의 기준이 됩니다.
              </div>
            </li>
          </ul>
        </div>

        {/* homepage */}
        <div data-contain="homepage" className="active">
          <ul className="list_wrap4">
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 탁송비가 왜 차이가 나나요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                모든 차량은 평택에서 차량 검수 후 각 지역으로 탁송되며 이에 따라 거리에 따른 실비가
                적용 됩니다. (단, 보다 상세한 사항은 견적시 확인한 비용과 실제 탁송비가 달라질 수
                있습니다.)
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 견적 산출 및 출력이 가능한가요?</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                상단 [구매] [견적] 탭을 클릭 하시고 색상 및 옵션, 탁송 지역까지 산출하시면 견적이
                산출 됩니다. 산출 이후 견적서 다운로드를 통해서 PDF 파일 및 프린터를 통해 출력이
                가능합니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 구매 예약 신청은 무엇인가요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                “카카오T” 앱을 통해 구매예약을 신청할 수 있습니다. 추후에는 T4K 전용 홈페이지에서
                견적서 작성이후 구매예약 신청을 하실 수 있습니다. 단, 구매예약 신청이 실제 계약 및
                배정이 아닌 구매 상담을 위한 사항으로 지역 담당 영업담당자를 통해서 상담이 이루어
                집니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. EV 절약계산기는 무엇인가요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                전기차와 디젤차량의 연비를 비교할 수 있는 계산기 입니다. 디젤 주유비 및 연비 그리고
                주행거리 및 회수 등을 입력하시면 전기차의 절약 금액을 확인하실 수 있습니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. 사이트에 별도 회원 가입 기능은 없나요? </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                개인정보보호 등의 사유로 사이트에서 별도 회원 가입은 받고 있지 않습니다. 다만 차량
                구매를 위한 [구매 상담]시 개인정보 작성이 요구되니 참고 하시길 바랍니다.
              </div>
            </li>
          </ul>
        </div>

        {/* etc */}
        <div data-contain="etc" className="active">
          <ul className="list_wrap4">
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">
                  Q. 전기차 구매보조금 지급 현황은 어떻게 확인할 수 있나요?
                </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                전기차 보조금은 정부에서 운영 하는 사항으로 자세한 사항은 '무공해차 통합 누리집'
                사이트에서 확인할 수 있습니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap" onClick={accoOpen}>
                <strong className="tlt">Q. GS글로벌은 BYD와 어떤 관계 인가요?</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                GS 글로벌은 BYD 상용차의 공식 임포터 입니다. 임포터란 국토교통부가 승인한
                해외제작사의 국내 대리인으로 판매의 권한자이자 사후관리의 책임자 입니다.
              </div>
            </li>
          </ul>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Faq;
