import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { isEmpty, set } from 'lodash';
import requests from '../../../../../../api/requests';
import { getReviewCommentList, getCommentDetail } from '../../../../../../utile/slice/boardSlice';
import { MyUploadAdapter } from '../../../../../../utile/MyUploadAdapter';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CommentSection({ bbsSeq, bbsPassword }) {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const [reqParams, setReqParams] = useState(
    state ? state : {bbsSeq: bbsSeq, bbsPassword: bbsPassword}
  );
  const [commentUserNm, setCommentUserNm] = useState('');
  const [password, setPassword] = useState('');
  const [commentCont, setCommentCont] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [targetCommentCont, setTargetCommentCont] = useState('');
  const [showPasswordLayer, setShowPasswordLayer] = useState(false);
  const [actionType, setActionType] = useState('');
  const [targetCommentSeq, setTargetCommentSeq] = useState(null);
  const [targetCommentUserNm, setTargetCommentUserNm] = useState(null);
  const [targetPassword, setTargetPassword] = useState('');
  const commentList = useAppSelector(state => state.board.commentList) || [];
  const commentDetail = useAppSelector((state) => state.board.commentDetail); 
  const Page = useAppSelector((state) => state.board.paginationData);
  const { resultCode } = useAppSelector((state) => state.boardForm);
  const commentTotalCount = useAppSelector(state => state.board.commentTotalCount) || 0;
  const [hasMore, setHasMore] = useState(true); // 더 불러올 댓글이 있는지 여부
  const observer = useRef(); // IntersectionObserver를 위한 ref
  const [pageNo, setPageNo] = useState(state ? state : 1);
  const editorRef = useRef(null);

  useEffect(() => {
    loadCommentList(bbsSeq, bbsPassword, pageNo);
  }, [bbsSeq, bbsPassword, pageNo ]);

  const loadCommentList = (bbsSeq, bbsPassword, pageNo) => {
    const params = {
      bbsSeq,
      bbsPassword,
      pageNo
    };
    dispatch(getReviewCommentList(params));
  };

  const lastCommentElementRef = useRef();

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      setPageNo((prevPageNo) => prevPageNo + 1); // 페이지 증가하여 더 많은 댓글을 로드
    }
  };

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    observer.current = new IntersectionObserver(handleObserver, option);
    if (lastCommentElementRef.current) {
      observer.current.observe(lastCommentElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [lastCommentElementRef.current, hasMore]);

  const handleClosePopup = () => {
    resetTarget();
  };

  const handleCommentSubmit = () => {
    if (isEmpty(commentUserNm) || isEmpty(password) || isEmpty(commentCont)) {
      alert('이름, 비밀번호, 댓글 내용을 모두 입력해주세요.');
      return;
    }

    const newComment = {
      bbsSeq,
      commentUserNm,
      password,
      commentCont,
    };

    // api 호출
    fetch(`${requests.COMMENTS}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newComment),
    })
      .then((response) => response.json())
      .then(() => {
        resetTarget2();
        loadCommentList(bbsSeq, bbsPassword, 1);
      })
      .catch((error) => {
        alert('댓글 등록 중 오류가 발생했습니다.');
      });
  };

  const handleEditSubmit = () => {

    fetch(`${requests.COMMENTS}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        bbsSeq: bbsSeq,
        commentSeq: targetCommentSeq,
        commentUserNm: targetCommentUserNm,
        commentCont: targetCommentCont,
        password: targetPassword
      })
    })
      .then(() => {
        resetTarget();
        loadCommentList(bbsSeq, bbsPassword, pageNo);
      })
      .catch((error) => {
        alert('댓글 수정 중 오류가 발생했습니다.');
      });
  };

  const handleDeleteClick = (commentSeq) => {
    resetTarget();

    setTargetCommentSeq(commentSeq);
    setActionType('delete');
    setShowPasswordLayer(true);
  };

  const handleEditClick = (commentSeq) => {
    resetTarget();

    setTargetCommentSeq(commentSeq);
    setActionType('edit');
    setShowPasswordLayer(true);
  };

  const handleDeleteConfirm = () => {
    fetch(`${requests.COMMENTS}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        commentSeq: targetCommentSeq,
        password: targetPassword
      })
    })
    .then(() => {
      resetTarget();
      loadCommentList(bbsSeq, bbsPassword, 1);
    })
    .catch((error) => {
      alert('댓글 삭제 중 오류가 발생했습니다.');
    });
  };

  const resetTarget = () => {
    setIsEditing(false);
    setActionType('');
    setShowPasswordLayer(false);
    setTargetCommentSeq(null);
    setTargetCommentUserNm('');
    setTargetCommentCont('');
    setTargetPassword('');
  };

  const resetTarget2 = () => {
    setCommentCont('');
    setCommentUserNm('');
    setPassword('');

    editorRef.current.setData('');
  };

  const handleEditCancel = () => {
    resetTarget();
  };

  //비밀번호 확인 - 수정, 삭제에 사용.
  const checkPassword = (event) => {
    const parentNode = event.target.parentNode;
    const input = parentNode.querySelector('[type=password]').value;

    if(input === ""){
      alert("비밀번호를 입력해주세요.");
      return false;
    }

    setTargetPassword(input);
    verifyPassword(input);
  }

  const verifyPassword = (input) => {

    fetch(`${requests.COMMENTS}/${targetCommentSeq}${requests.CHECK_PASSWORD}`, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          bbsSeq: targetCommentSeq,
          password: input
      })
    })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw Error(`Request rejected with status ${response.status}`);
      }
    })
    .then(res => res.json())
    .then(res => {
      if (res.data.sameYn) {
        if(actionType === 'edit'){
          setIsEditing(true);
          setShowPasswordLayer(false);
          dispatch(getCommentDetail({ commentSeq: targetCommentSeq, password: input }));
        } else if(actionType === 'delete'){
          setShowPasswordLayer(false);
        }
      } else{
        alert("비밀번호가 일치하지 않습니다.");
      }
    })
    .catch(err => {
      alert("오류가 발생했습니다. 잠시후 다시 시도해주세요.");
    });
  }

  // 공통 CKEditor 설정 함수
  const registerEditorConfig = (editor) => {
    if (!editor._initializedCustomConversion) {
      // 업로드 어댑터 설정
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader, editor); 
      };

      // 업캐스트 변환 (oembed -> iframe)
      editor.conversion.for('upcast').add((dispatcher) => {
        dispatcher.on('element:oembed', (evt, data, conversionApi) => {
          const viewElement = data.viewItem;

          if (
            viewElement &&
            viewElement.getAttribute('url') &&
            conversionApi.consumable.test(viewElement, { name: true, attributes: ['url'] })
          ) {
            const url = viewElement.getAttribute('url');

            // iframe 요소 생성
            const iframeElement = conversionApi.writer.createContainerElement('iframe', {
              src: url,
              frameborder: '0',
              allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
              allowfullscreen: 'true',
            });

            // 변환 결과 추가 및 소모
            conversionApi.writer.insert(iframeElement, data.modelCursor);
            conversionApi.consumable.consume(viewElement, { name: true, attributes: ['url'] });
            evt.stop(); // 기본 동작 중단
          }
        });
      });

      // 다운캐스트 변환 (model -> iframe)
      editor.conversion.for('downcast').elementToElement({
        model: 'media',
        view: (modelElement, { writer }) => {
          const url = modelElement.getAttribute('url');
          return writer.createContainerElement('iframe', {
            src: url,
            frameborder: '0',
            allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
            allowfullscreen: 'true',
            style: 'width: 640px; height: 360px;',
          });
        },
      });

      editor._initializedCustomConversion = true;
    }
  };

  useEffect(() => {
    if (commentDetail && actionType === 'edit') {
      setTargetCommentCont(commentDetail.commentCont);
      setTargetCommentUserNm(commentDetail.commentUserNm);
    }
  }, [commentDetail, actionType]);

  return (
    <>
    <div className="comment-section inner">
      <div className="comment-form">
        <h4>댓글 작성</h4>
        <div className="comment-form-box">
          <div className="comment-input">
            <div className='input01'>
            <input
              type="text"
              placeholder="작성자"
              value={commentUserNm}
              onChange={(e) => setCommentUserNm(e.target.value)}
            />
            <input
              type="password"
              placeholder="비밀번호"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            </div>
            <CKEditor
              key="new-editor"
              editor={ClassicEditor}
              data={commentCont}
              onChange={(event, editor) => setCommentCont(editor.getData())}
              onReady={(editor) => {
                editorRef.current = editor;
                registerEditorConfig(editor);
              }}
              config={{
                ckfinder: {
                  uploadUrl: `${requests.COMMENTS}/upload`,
                },
                mediaEmbed: {
                  previewsInData: true,
                },
              }}
            />
          </div>
          <button className='submitBtn btn_primary' onClick={handleCommentSubmit}>
            댓글 등록
          </button>
        </div>
      </div>

      <div className="comment-list">
      <h4>댓글({commentTotalCount})</h4>
        {commentList.map((comment, index) => (
          <div
            key={comment.commentSeq}
            className="comment-item"
            ref={commentTotalCount === index + 1 ? lastCommentElementRef : null}
          >
            <div className="comment-header">
              <div className="comment-info">
                <span className="comment-writer">
                  {comment.isAdmin === 'Y' && <span className="ico_admin">공식</span>}
                  {comment.commentUserNm}
                </span>
                <span className='comment-date'>{comment.createDate}</span>
              </div>
            </div>
            {isEditing && targetCommentSeq === comment.commentSeq ? (
              <div className="edit-comment-box">
                <CKEditor
                  key={`edit-${targetCommentSeq}`}
                  editor={ClassicEditor}
                  data={targetCommentCont}
                  onChange={(event, editor) => setTargetCommentCont(editor.getData())}
                  onReady={(editor) => {
                    registerEditorConfig(editor);
                  }}
                  className="edit-comment-input"
                  config={{
                    ckfinder: {
                      uploadUrl: `${requests.COMMENTS}/upload`,
                    },
                    mediaEmbed: {
                      previewsInData: true,
                    },
                  }}
                />
              </div>
            ) : (
              <p className="desc editor-desc" dangerouslySetInnerHTML={{ __html: comment.commentCont }} />
            )}
            <div className="comment-actions">
              {isEditing && targetCommentSeq === comment.commentSeq ? (
                <>
                  <button onClick={handleEditSubmit} className='edit'>수정완료</button>
                  <button onClick={handleEditCancel} className='cancel'>취소</button>
                </>
              ) : (
                <>
                  <button onClick={() => handleEditClick(comment.commentSeq)} className='modify'>수정</button>
                  <button onClick={() => handleDeleteClick(comment.commentSeq)} className='delete'>삭제</button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {showPasswordLayer === true && (
        <div className="boardpop_wrap">
          <button type="button" title="팝업 닫기" class="ico_22_pop_close pwPopClose" onClick={handleClosePopup}></button>
          <div className="conf_inner">
            <div className="tlt">비밀번호 입력</div>
            <input type="password" className="input_wrap"/>
            <input type="button" className="btn_wrap" value="확인" onClick={checkPassword}/>
          </div>
        </div>
      )}
      {actionType === 'delete' && showPasswordLayer === false && (
        <div className="boardpop_wrap">
          <button type="button" title="팝업 닫기" className="ico_22_pop_close pwPopClose" onClick={handleClosePopup}></button>
          <div className="conf_inner">
            <div className="tlt">삭제 확인</div>
            <p>삭제된 데이터는 복구되지 않습니다. 삭제하시겠습니까?</p>
            <div className="btn-group">
              <button className="confirm-delete-btn" onClick={handleDeleteConfirm}>확인</button>
              <button className="cancel-btn" onClick={handleClosePopup}>취소</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
}

export default CommentSection;
