import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import AlartPopUp from '../../../common/AlartPopUp';
import useVerifyMessageBoardFormHook from '../../../../utile/hook/useVerifyMessageBoardFormHook';
import requests from '../../../../api/requests';
import { autoHypen } from '../../../../utile/regExp';
import { getCustomerDetail } from '../../../../utile/slice/boardSlice';
import { isEmpty } from 'lodash';
import { MyUploadAdapter } from '../../../../utile/MyUploadAdapter';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  actShowDepth1LPopUp,
  actCb1LPopUp,
} from '../../../../utile/slice/layerPopUpSlice';
import PersonalInfoPop from '../../../common/popup/PersonalInfo';

function QnaWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [reqParams, setReqParams] = useState(
    state ? state : { bbsSeq: 0, password: '' }
  );
  const [bbsSeq, setBbsSeq] = useState(0);
  const [mode, setMode] = useState('insert');
  const [titNm, setTitNm] = useState('');
  const [userNm, setUserNm] = useState('');
  const [userContact, setUserContact] = useState('');
  const [userMail, setUserMail] = useState('');
  const [password, setPassword] = useState('');
  const [cont, setCont] = useState('');
  const [secretYn, setSecretYn] = useState(false);

  const dispatch = useAppDispatch();
  const qnaDetail = useAppSelector(state => state.board.customerDetail);

  //약관이벤트 시작
  const { isCb1LPopUp, depth1LPopUpName } = useAppSelector(
      (state) => state.layerPopUp,
    );    

  const onClickPersonalInfo = () => {
    let prsInfoCltAgrCont = getValues('prsInfoCltAgrCont');

    if (prsInfoCltAgrCont) {
      setValue('prsInfoCltAgrCont', false);
      dispatch(actCb1LPopUp('unChecked'));
    } else {
      dispatch(actCb1LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('personal'));
    }
  };

  useEffect(() => {
    if (isCb1LPopUp === 'unChecked') {
      setValue('prsInfoCltAgrCont', false);
    } else if (isCb1LPopUp === 'checked') {
      setValue('prsInfoCltAgrCont', true);
    } else return;
  }, [isCb1LPopUp]);
  //약관이벤트 끝


  // 페이지 로드 또는 state 변경 시 실행
  useEffect(() => {
    // 수정 모드 판별
    if (state?.bbsSeq > 0) {
      setMode('update');
      dispatch(getCustomerDetail({ bbsSeq: state.bbsSeq, password: state.password }));
    } else {
      // 등록 모드 초기화
      setMode('insert');
      resetForm();
    }
  }, [state, dispatch]);

  // 상세 정보가 로드되었을 때 폼 데이터 설정
  useEffect(() => {
    if (mode === 'update' && !isEmpty(qnaDetail)) {
      setBbsSeq(qnaDetail.bbsSeq);
      setTitNm(qnaDetail.bbsTitNm);
      setUserNm(qnaDetail.bbsUserNm);
      setUserContact(qnaDetail.bbsUserContact);
      setUserMail(qnaDetail.bbsUserMail);
      setPassword(''); // 비밀번호는 비공개 정보이므로 초기화에 포함되지 않음
      setCont(qnaDetail.bbsCont);
      setSecretYn(qnaDetail.secretYn);
      setValue('bbsSeq', qnaDetail.bbsSeq);
    } else {
      resetForm();
    }
  }, [qnaDetail, mode]);

  // 폼 초기화 함수
  const resetForm = () => {
    setBbsSeq(0);
    setTitNm('');
    setUserNm('');
    setUserContact('');
    setUserMail('');
    setPassword('');
    setCont('');
    setSecretYn(false);
  };

  const alartInfo = {
    fail: {
      message: `게시글 등록에 실패했습니다.<br>잠시 후 다시 시도해 주세요.`,
      cntBtn: 1,
    },
  };

  const { isShowAPopUp } = useAppSelector((state) => state.alartPopUp);
  useEffect(() => {
    register('bbsTitNm', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('bbsUserNm', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('bbsUserContact', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[0-9-]$/,
        message: '번호가 형식에 맞지 않습니다.',
      },
    });
    register('bbsUserMail', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/,
        message: '이메일이 형식에 맞지 않습니다.',
      },
    });
    register('bbsCont', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('password', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
  }, []);

  useEffect(() => {
    setValue('bbsTitNm', titNm);
  }, [titNm]);

  useEffect(() => {
    setValue('bbsUserNm', userNm);
  }, [userNm]);

  useEffect(() => {
    setValue('bbsUserContact', userContact);
  }, [userContact]);

  useEffect(() => {
    setValue('bbsUserMail', userMail);
  }, [userMail]);

  useEffect(() => {
    setValue('password', password);
  }, [password]);

  useEffect(() => {
    setValue('bbsCont', cont);
  }, [cont]);

  useEffect(() => {
    setValue('secretYn', secretYn);
  }, [secretYn]);

  const { checkTitNm, checkUserNm, checkUserContact, checkUserMail, checkPassword, checkCont } = useAppSelector((state) => state.verifyMessage);

  const { register, handleSubmit, onSubmit, onError, setValue, reset, getValues  } =
    useVerifyMessageBoardFormHook(alartInfo, requests.CUSTOMER);

  const { resultCode } = useAppSelector((state) => state.boardForm);

  useEffect(() => {
    if (resultCode === "00") {
      alert("등록되었습니다.");
    } else if (resultCode === "02") {
      alert("비밀번호가 일치하지 않습니다.");
    } else if (resultCode === "03") {
      alert("존재하지 않는 게시글 입니다.");
    }

    if (resultCode === "00" || resultCode === "03") {
      reset();

      setTitNm('');
      setUserNm('');
      setUserContact('');
      setUserMail('');
      setPassword('');
      setCont('');
      setSecretYn(false);
      navigate('/customer/qna');
    }

  }, [resultCode, reset, dispatch, setTitNm, setUserNm, setUserContact, setUserMail, setPassword, setCont, navigate]);

  const goDelete = () => {
    if (password === "") {
      alert("비밀번호를 입력해주세요.");
      return;
    }
    fetch(requests.CUSTOMER, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bbsSeq: bbsSeq,
        password: password
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.resultCode) {
          if (res.data.resultCode === "00") {
            alert("삭제가 완료되었습니다.");
            navigate('/customer/qna');
          } else if (res.data.resultCode === "01") {
            alert("비밀번호를 입력해주세요.");
          } else if (res.data.resultCode === "02") {
            alert("비밀번호가 일치하지 않습니다.");
          } else if (res.data.resultCode === "03") {
            alert("존재하지 않는 게시글입니다.");
          }
        } else {
          alert("정상적으로 처리되지 않았습니다.");
        }
      });
  };

  return (
    <div className="qna_write inner">
      {isShowAPopUp && <AlartPopUp />}
      {depth1LPopUpName === 'personal' && <PersonalInfoPop />}
      <div className="visual">
        <h2 className="tlt">고객게시판</h2>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit, onError)}>
        {mode === 'update' && (
          <input type="hidden" {...register('bbsSeq')} />
        )}
        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="secret"
              name="secretYn"
              value="Y"
              checked={secretYn === 'Y'}
              onChange={(e) => {
                setSecretYn(e.target.checked ? 'Y' : 'N');
              }}
            />
            <label htmlFor="secret">
              비밀글
            </label>
          </div>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label3">
              제목
            </label>
            <input
              id="input1"
              name="bbsTitNm"
              type="text"
              placeholder="제목을 입력하세요."
              className="input_txt2"
              value={titNm}
              onChange={(e) => {
                setTitNm(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkTitNm}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input2" className="label3">
              작성자
            </label>
            <input
              id="input2"
              name="bbsUserNm"
              type="text"
              placeholder="이름을 입력하세요."
              className="input_txt2"
              value={userNm}
              onChange={(e) => {
                setUserNm(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkUserNm}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input3" className="label3">
              연락처
            </label>
            <input
              id="input3"
              name="bbsUserContact"
              type="text"
              placeholder="010-1234-5678"
              className="input_txt2"
              maxLength={14}
              value={autoHypen(userContact)}
              onChange={(e) => {
                setUserContact(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkUserContact}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input4" className="label3">
              이메일
            </label>
            <input
              id="input4"
              name="bbsUserMail"
              type="text"
              placeholder="이메일을 입력하세요."
              className="input_txt2"
              value={userMail}
              onChange={(e) => {
                setUserMail(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkUserMail}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input5" className="label3">
              비밀번호 {mode === "modify" ? "확인" : "등록"}
            </label>
            <input
              id="input5"
              name="password"
              type="password"
              placeholder="비밀번호를 입력하세요."
              className="input_txt2"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkPassword}</span>
        </div>
        <div className="form_wrap line_ty typeTop">
          <div className="input_wrap textarea_wrap">
            <label htmlFor="input1" className="label3">
              내용
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={cont}
              onChange={(event, editor) => setCont(editor.getData())}
              onReady={(editor) => {
                // CKEditor가 준비되었을 때 업로드 어댑터를 설정합니다.
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                  return new MyUploadAdapter(loader, editor); // MyUploadAdapter를 반환
                };

                // MediaEmbed 업캐스트 변환: oembed -> iframe
                editor.conversion.for('upcast').add((dispatcher) => {
                  dispatcher.on('element:oembed', (evt, data, conversionApi) => {
                    const viewElement = data.viewItem;

                    if (viewElement && viewElement.getAttribute('url')) {
                      const url = viewElement.getAttribute('url');
                      const iframeElement = conversionApi.writer.createContainerElement('iframe', {
                        src: url,
                        frameborder: '0',
                        allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                        allowfullscreen: 'true',
                        style: 'width: 640px; height: 360px;',
                      });

                      conversionApi.writer.insert(iframeElement, data.modelCursor);
                      conversionApi.consumable.consume(viewElement, { name: true, attributes: ['url'] });
                    }

                    evt.stop();
                  });
                });

                // MediaEmbed 다운캐스트 변환: model -> iframe
                editor.conversion.for('downcast').elementToElement({
                  model: 'media',
                  view: (modelElement, { writer }) => {
                    const url = modelElement.getAttribute('url');
                    return writer.createContainerElement('iframe', {
                      src: url,
                      frameborder: '0',
                      allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                      allowfullscreen: 'true',
                      style: 'width: 640px; height: 360px;',
                    });
                  },
                });
              }}
              config={{
                ckfinder: {
                  uploadUrl: `${requests.CUSTOMER}/upload`, // 업로드 URL 설정
                },
                mediaEmbed: {
                  previewsInData: true, // 데이터에 미리보기 포함
                },
              }}
            />
          </div>
          <span className="err_msg">{checkCont}</span>
        </div>

        <div className="input_checkbox_basic">
          <div className="box_checkbox flexTop">
            <input
              type="checkbox"
              id="agree"
              name="item1"
              checked={getValues('prsInfoCltAgrCont') && isCb1LPopUp === 'checked' ? 'checked' : ''}
              onChange={() => {
                return;
              }}
            />
            <label
              className="input_checkbox"
              {...register('prsInfoCltAgrCont', {
                required: '약관에 동의하셔야 글등록이 완료됩니다..',
              })}
              onClick={onClickPersonalInfo}
            >
              개인정보 수집과 이용에 동의합니다.<br/><span class="point">개인정보를 정확하게 입력하지 않을 시 게시글이 삭제될 수 있습니다.</span>
            </label>
          </div>          
        </div>        

        {bbsSeq !== 0 ?
          <div className='btn_layout right'>
            <input type='button' className="btn_ghost_m" onClick={goDelete} value="삭제하기" />
            <button className="btn_primary_m"><span>수정하기</span></button>
          </div>
          :
          <div className='btn_layout right'>
            <button className="btn_primary_m"><span>등록하기</span></button>
          </div>}
      </form>
    </div>
  );
}

export default QnaWrite;
