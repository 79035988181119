class MyUploadAdapter {
    constructor(loader, editor) {
        this.loader = loader; // 파일 로더 인스턴스
        this.editor = editor;
    }

    // 파일 업로드를 처리하는 메서드
    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    const data = new FormData();
                    data.append('upload', file); // 파일 데이터 추가

                    // 업로드 URL을 CKEditor 설정에서 가져옴
                    const uploadUrl = this.editor.config.get('ckfinder.uploadUrl');
                    if (!uploadUrl) {
                        reject('Upload URL is not defined.');
                        return;
                    }

                    fetch(uploadUrl, {
                        method: 'POST',
                        body: data, // 파일을 포함한 데이터 전송
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((result) => {
                            if (result.resultCode === true) {
                                resolve({
                                    default: result.resultMsg, // 서버에서 반환한 URL 설정
                                });
                            } else {
                                console.error('Upload failed:', result.resultMsg);
                                reject(`업로드 실패: ${result.resultMsg}`);
                            }
                        })
                        .catch((error) => {
                            console.error('Error during upload:', error);
                            reject(`업로드 중 오류 발생: ${error}`);
                        });
                })
        );
    }

    // 업로드 중단 처리 메서드 (필요시 구현 가능)
    abort() {
    }
}

// CKEditor에 커스텀 업로드 어댑터 플러그인을 등록하는 함수
export function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader, editor);
    };
}

export { MyUploadAdapter };