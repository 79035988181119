import React from 'react';
import LazyLoad from 'react-lazyload';
import { Swiper, SwiperSlide } from 'swiper/react';


function T4KEtc() {
  return (
    <div className="features_tab">
      <section className="visual_title">
        <div className="visual_tit_img t4k_etc">
        </div>
        <div className="inner_box">
          <h2 className="title" data-trigger>기타 특장차</h2>
          <p className='desc' data-trigger>T4K 윙바디 /  T4K 파워게이트 / T4K 푸드 &amp; 커피트럭</p>
        </div>
      </section>

      <section className="sectionDiv features_section">
        <div className="inner_box flexBox">
          <div className="title_wrap">
            <h2 className="title" data-trigger>T4K 윙바디</h2>
            <p data-trigger>적재함 양옆 전체 개방으로 화물의 위치와 상관없이 자유로운 상하역이 가능해 화물 작업의 효율성을 높여줍니다.</p>
          </div>
          <div className="box_features">
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 특징</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>화물을 적재하거나 하역할 때 측면에서의 작업을 보다 효율적으로 할 수 있습니다.</li>
                <li>윙 오픈 모드는 수동과 자동 중 선택 가능합니다.</li>
                <li>공기 저항을 고려해 제작한 전용 에어 스포일러로 주행 효율성을 높였습니다.</li>
              </ul>
            </div>
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 분야</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>가전·가구 등 측면 상하차가 필요한 부피 화물의 운송 · 팔레트를 활용한 화물 적재</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionDiv features_slide_section">
        <div className="box_feature_img wingBody"></div>
        <div className="inner_box">
          <div className="box_features_slider" data-trigger>
            <LazyLoad>
              <Swiper
                className="box_features_swiper"
                slidesPerView="auto"
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide className="section_boxf_slide wBody">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>상하 오픈식 사이드 도어</h3>
                    <p>좌·우치 모두 개발 가능, 지게차와의 연계 작업으로 향상적인 적재 및 하연 업무</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide wBody">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>윈&amp;사이드 게이트</h3>
                    <p>사이드 도어의 알루미닛 소재 적용으로 차량 경량화</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide wBody">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>자동 윈 개피 장치 (옵션)</h3>
                    <p>전동모터를 통한 동력으로 쉽고 편리한 사이드 윈 개피<br />* 기본 : 수동 윈 개피 장치</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide wBody">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>유압실림더 및 작동 스윗치 (옵션)</h3>
                    <p>전동 유압 파웨팩을 적용해 버튼 하나로 간단한 윈 개피 및 각도 조정 가능, 2점 지지방식의 복동식 적용으로 유압 실림더 안전성 향상</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className="sectionDiv spec_section">
        <div className='inner_box narrow pdt0'>
          <div className="title_wrap">
            <h2 className="title" data-trigger>T4K 윙바디 주요 제원</h2>
          </div>
          <div className='spec_wrap'>
            <div className='specTableWrap table_wrap'>
              <div className='table_scroll_wrap' data-trigger>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan="2" colSpan="2">구분</th>
                      <th rowSpan="2">최대 적재량 (kg)</th>
                      <th colSpan="3">차량 제원 (mm)</th>
                      <th colSpan="3">윙바디 제원 (mm)</th>
                      <th rowSpan="2">판매가<br />(단위:천원/VAT포함)</th>
                    </tr>
                    <tr>
                      <th>전장</th>
                      <th>전폭</th>
                      <th>전고</th>
                      <th>전장</th>
                      <th>전폭</th>
                      <th className='borderRight'>전고</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="2">기본 윙바디</td>
                      <td>수동</td>
                      <td>1,000</td>
                      <td>5,870</td>
                      <td>1,800</td>
                      <td>2,700</td>
                      <td>3,450</td>
                      <td>1,720</td>
                      <td>1,800</td>
                      <td>8,100</td>
                    </tr>
                    <tr>
                      <td>자동</td>
                      <td>1,000</td>
                      <td>5,870</td>
                      <td>1,800</td>
                      <td>2,700</td>
                      <td>3,450</td>
                      <td>1,720</td>
                      <td>1,800</td>
                      <td>10,080</td>
                    </tr>
                    <tr>
                      <td rowSpan="2">하이 윙바디</td>
                      <td>수동</td>
                      <td>1,000</td>
                      <td>5,870</td>
                      <td>1,800</td>
                      <td>2,900</td>
                      <td>3,450</td>
                      <td>1,720</td>
                      <td>2,000</td>
                      <td>8,720</td>
                    </tr>
                    <tr>
                      <td>자동</td>
                      <td>1,000</td>
                      <td>5,870</td>
                      <td>1,800</td>
                      <td>2,900</td>
                      <td>3,450</td>
                      <td>1,720</td>
                      <td>2,000</td>
                      <td>10,770</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionDiv features_section">
        <div className="inner_box flexBox">
          <div className="title_wrap">
            <h2 className="title" data-trigger>T4K 파워게이트</h2>
            <p data-trigger>리모컨을 통한 게이트 작동이 쉽고 빨라 효율적인 작업이 가능합니다.</p>
          </div>
          <div className="box_features">
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 특징</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>무거운 화물도 쉽고 편리하게 상하차 할 수 있습니다.</li>
                <li>상하 수직 이송 방식으로 안정된 작업이 가능합니다.</li>
              </ul>
            </div>
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 분야</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>이삿짐센터, 가전제품 취급점 · 자판기 업체, 악기상 · 프로판가스점, 유류 수송</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionDiv features_slide_section">
        <div className="box_feature_img powerGate"></div>
        <div className="inner_box">
          <div className="box_features_slider" data-trigger>
            <LazyLoad>
              <Swiper
                className="box_features_swiper"
                slidesPerView="auto"
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide className="section_boxf_slide powerG">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>2단 접이식 자동 개폐 버튼</h3>
                    <p>버튼 하나로 편리하게 상하차 및 개폐 조작 가능</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide powerG">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>베어링 스틸 가공 &amp; 6T 기둥 절곡물</h3>
                    <p>튼튼하고 두꺼운 소재 적용으로 내구성 강화</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide powerG">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>리프트 플레이트</h3>
                    <p>피아노, 대형 냉장고 등 부피 큰 화물 적재</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide powerG">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>전동 유압식 리프트 시스템</h3>
                    <p>무거운 화물도 안전하게 상하차 가능</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className="sectionDiv spec_section">
        <div className='inner_box narrow pdt0'>
          <div className="title_wrap">
            <h2 className="title" data-trigger>T4K 파워게이트 주요 제원</h2>
          </div>
          <div className='spec_wrap box_spec'>
            <div className='specTableWrap table_wrap'>
              <div className='table_scroll_wrap' data-trigger>
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{}} />
                    <col style={{}} />
                    <col style={{}} />
                    <col style={{}} />
                    <col style={{}} />
                    <col style={{}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th rowSpan="2">구분</th>
                      <th rowSpan="2">최대 적재량 (kg)</th>
                      <th colSpan="3">차량 제원 (mm)</th>
                      <th colSpan="3">적재실 제원 (mm)</th>
                    </tr>
                    <tr>
                      <th>전장</th>
                      <th>전폭</th>
                      <th>전고</th>
                      <th>전장</th>
                      <th>전폭</th>
                      <th>전고</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>파워게이트</td>
                      <td>1,000</td>
                      <td>5,310</td>
                      <td>1,770</td>
                      <td>2,100</td>
                      <td>2,910</td>
                      <td>1,670</td>
                      <td>360</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='table_scroll_wrap' data-trigger>
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '35%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>세부옵션</th>
                      <th>사양</th>
                      <th>판매가 (단위:천원/VAT포함)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="2">파워게이트</td>
                      <td>내장탑차</td>
                      <td>단단동</td>
                      <td>4,235</td>
                    </tr>
                    <tr>
                      <td>카고</td>
                      <td>수직형 리프트</td>
                      <td>3,630</td>
                    </tr>
                    <tr className="remarks">
                      <td>비고</td>
                      <td colSpan="3">
                        <ul>
                          <li>* E-Track, 파워리프트 재질: STS </li>
                          <li>* 파워게이트(단단동/수직형리프트)LIFT 능력: 600kg </li>
                          <li>* 파워게이트(단단동): 1,700mm X 1,200mm</li>
                          <li>* 파워게이트(수직형리프트) : 1,500mm X 580mm(1단)/420mm(2단)</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionDiv features_section">
        <div className="inner_box flexBox">
          <div className="title_wrap">
            <h2 className="title" data-trigger>T4K 푸드 &amp; 커피트럭</h2>
            <p data-trigger>별도의 외부 전원 없이 내부 전력으로 다양한 전자기기를 사용할 수 있고 이동의 제한이 없어 커피 트럭 및 푸드 트럭에 적합합니다.</p>
          </div>
          <div className="box_features">
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 특징</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>차량 자체 전기로 TV, 전자레인지, 커피 머신 등을 실외에서 구동시킬 수 있고, 실내 220V 플러그로 노트북, 스마트패드, 보조 배터리 등의 충전이 가능합니다.</li>
                <li>냉장고 2대 배치가 가능할 정도로 적재 공간이 넓고 공간 활용도가 좋아 다양한 수납 공간 구성이 가능합니다.</li>
              </ul>
            </div>
            <div className='box_features_box'>
              <div className="title_wrap">
                <em class="tit_s border_line" data-trigger>주요 분야</em>
              </div>
              <ul className='box_features_list' data-trigger>
                <li>푸드 트럭, 커피 트럭 · 고급 캠핑카 · 이동형 목욕 트럭</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionDiv features_slide_section" >
        <div className="inner_box">
          <div className="box_features_slider">
            <LazyLoad>
              <Swiper
                className="box_features_swiper"
                slidesPerView="auto"
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide className="section_boxf_slide foodT">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>대용량 전력 자체 공급</h3>
                    <p>대용량 배터리 탑재로 커피 머신, 냉장고, 쇼케이스, 오븐 등 다양한 전자기기에 충분한 전력 공급 가능</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="section_boxf_slide foodT">
                  <div className='box_features_img'></div>
                  <div className="box_features_desc">
                    <h3>이동성 뛰어난 친환경 트럭</h3>
                    <p>V2L을 통한 자체 전력 공급으로 배출가스 및 위치적 제한이 없어 학교, 유치원, 공공기관 등에서도 활용 가능</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </LazyLoad>
          </div>
          <div className='btmText'>※ 당사는 차량 外 냉동탑차, 내장탑, 윙바디 등 개별 특장 차량을 출시, 제작, 납품하지 않으며 그에 관한 의무를 부담하지 아니합니다.
             상세한 사항은 각 딜러사 및 개별 특장사에 문의 부탁드립니다. 
          </div>
        </div>
      </section>
 
    </div>
  );
}

export default T4KEtc;
