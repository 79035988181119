import React, { useEffect, useRef } from 'react';
import RelationPage from '../../common/RelationPage';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PeopleImg from '../../../assets/images/img/mo_img_brand_byd_people.png';
import BydVideo from '../../../assets/media/byd_brand.mp4';

import { Swiper, SwiperSlide } from 'swiper/react';

const history = [
  { year: '2025', desc: 'BYD Korea, 승용 판매 시작' },
  { year: '2023', desc: 'GS글로벌, T4K 전기트럭 출시' },
  { year: '2020', desc: 'GS글로벌, BYD 한국 상용차 공식수입원 지정' },
  { year: '2019', desc: '글로벌 디자인센터 출범' },
  { year: '2017', desc: '세계 최초의 상용 SkyRail 노선 개통' },
  { year: '2016', desc: '자이드 지속가능성상 대형기업상 수상\n모노레일 사업영역 진출' },
  { year: '2015', desc: '세계 선도 EV 제조/판매업체 세계 선도 배터리 생산업체' },
  { year: '2014', desc: 'BYD의 남미 최초의 인산철 배터리 공장 계약 체결' },
  { year: '2010', desc: '벤츠사와 EV개발 JV 설립' },
  { year: '2008', desc: '워렌 버핏, BYD H주 인수' },
  { year: '2003', desc: '자동차 산업 진출' },
  { year: '2002', desc: '반도체 산업 진출' },
  { year: '2000', desc: '첫 번째 산업단지 설립' },
  { year: '1995', desc: 'BYD 설립' },
];

gsap.registerPlugin(ScrollTrigger);

function Byd() {
  const handleGsap = () => {
    const target = document.querySelectorAll('[data-trigger]');

    ScrollTrigger.matchMedia({
      scroller: '[data-snap]',
      // desktop
      '(min-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'bottom bottom',
                end: 'center 55%',
                // markers: true,
              },
            })
            .to(el, {
              duration: 1.4,
              opacity: 1,
              y: 0,
            });
        });
      },

      //   mobile
      '(max-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'center bottom',
                end: 'center 55%',
                // scrub: 1,
                // markers: true,
              },
            })
            .to(el, {
              duration: 0.8,
              opacity: 1,
              y: 0,
            });
        });
      },
    });
  };

  useEffect(() => {
    handleGsap();
  });

  return (
    <div className="byd">
      <section className="contain-byd1">
        <p className="txt-slogun" data-trigger>
          COOL THE EARTH <br className="mo_view" />
          BY ONE DEGREE
          <span className="txt_slo_sub">
            TECHNOLOGY <br className="mo_view" />
            GREEN FUTURE
          </span>
        </p>
        <video className="react-player" src={BydVideo} autoPlay playsInline muted loop />
      </section>
      <section className="contain-byd2">
        <h2 className="tit" data-trigger>
          더 나은 삶을 <br className="mo_view" />
          위한 기술 혁신
        </h2>
        <p className="txt-desc" data-trigger>
          BYD는 기술혁신으로 지속 가능한 <br className="mo_view" />
          미래를 만들기 위해 노력합니다.
        </p>
        <div className="box-items">
          <div className="item img1" data-trigger>
            {/* <div className="img-item img1"></div> */}
            <div className="txt-item">
              <strong>전기차</strong>
              Auto
            </div>
          </div>
          <div className="item img2" data-trigger>
            {/* <div className="img-item img2"></div> */}
            <div className="txt-item">
              <strong>전자제품</strong>
              Electronics
            </div>
          </div>
          <div className="item img3" data-trigger>
            {/* <div className="img-item img3"></div> */}
            <div className="txt-item">
              <strong>친환경에너지</strong>
              Renewable Energy
            </div>
          </div>
          <div className="item img4" data-trigger>
            {/* <div className="img-item img4"></div> */}
            <div className="txt-item">
              <strong>모노레일</strong>
              Rail Transit
            </div>
          </div>
        </div>
      </section>
      <section className="contain-byd3">
        <h2 className="tit" data-trigger>
          {/* 세계 최고의 제조사에서
          <br />
          프리미엄 전기차 분야의 <br />
          선두주자로 */}
          친환경자동차의 선두자
        </h2>
        <p className="txt-desc" data-trigger>
          2004년 첫 순수 전기 컨셉카를 선보인 이후, <br className="mo_view" />
          BYD는 현재 전 세계적으로 300만대의 친환경 자동차를 생산하는 <br className="mo_view" />
          세계 최대 규모의 친환경 자동차 회사로 성장했습니다.
        </p>
        <p className="txt-desc" data-trigger>
          BYD의 25년 이상 축적된 배터리 생산 <br className="mo_view" />
          노하우는 높은 기술력과 안정성을 기반으로
          <br />
          자동차 제조를 넘어서 사람들에게 안전한 <br className="mo_view" />
          이동의 자유를 제공하고 있습니다.
        </p>
        <p className="txt-desc" data-trigger>
          이제 BYD는 프리미엄 친환경 자동차 분야의 <br className="mo_view" />
          선두주자로 자동차 산업의 미래로 한 걸음 <br className="mo_view" />더 나아가고 있습니다.
        </p>
        <div className="box-category">
          <div className="item">
            <div className="img" data-trigger></div>
            <div className="desc">
              <h3 className="tit" data-trigger>
                Total EV Solution
              </h3>
              <p className="txt-desc" data-trigger>
                세계에서 입증된 신뢰있는 기술력
                <br />
                10년 이상 도로 주행 경험의 전기차 업계 1위
                <br />전 세계 7만대 이상의 전기 버스 납품
              </p>
            </div>
          </div>
          <div className="item">
            <div className="img" data-trigger></div>
            <div className="desc">
              <h3 className="tit" data-trigger>
                Battery
              </h3>
              <p className="txt-desc" data-trigger>
                자체 배터리 생산공장
                <br />
                25년 이상 축적된 배터리 생산 노하우
                <br />
                최근 Blade Battery 개발
              </p>
            </div>
          </div>
          <div className="item">
            <div className="img" data-trigger></div>
            <div className="desc">
              <h3 className="tit" data-trigger>
                Plant
              </h3>
              <p className="txt-desc" data-trigger>
                미국, 캐나다, 브라질, 일본, 헝가리, 인도 등 <br />
                6개 생산 기지 동시에 운영
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contain-byd4">
        <div className="box-people">
          <h2 className="tit" data-trigger>
            주목을 이끌어 내는 힘
          </h2>
          <p className="txt-desc" data-trigger>
            중국 선전 Design Center는
            <br /> 아우디와 알파로메오의 디자인 수장을 <br />
            역임했던 볼프강 에거(Wolfgang Egger)
            <br />
            수석 디자이너가 이끌고 있습니다.
            <br />
            <br />
            이탈리아, 스페인, 스위스, 독일 등 10개국 <br />
            이상에서 온 200명 이상의 세계적인 디자이너가 <br />
            디자인을 진행합니다.
          </p>
          <img src={PeopleImg} alt="" className="mo_view" />
          <div className="people1">
            <span className="name" data-trigger>
              하인즈 케크 (Heinz Keck)
            </span>
            <span data-trigger>Chassis Tuning Chief Expert, BYD</span>
          </div>
          <div className="people2">
            <span className="name" data-trigger>
              볼프강 에거 (Wolfgang Egger)
            </span>
            <span data-trigger>Design Director, BYD</span>
          </div>
          <div className="people3">
            <span className="name" data-trigger>
              미하엘 아우치 파가네티 (Michele Jauch-Paganetti)
            </span>
            <span data-trigger>Global Interior Deisgn Director, BYD</span>
          </div>
        </div>
      </section>
      <section className="contain-byd5">
        <h2 className="tit" data-trigger>
          BYD History
        </h2>
        <div className="contain-history">
          <Swiper
            slidesPerView="auto"
            // loop={true}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            {history.map((item) => {
              return (
                <SwiperSlide key={item.name} className="item" data-trigger>
                  <div className="year">{item.year}</div>
                  <p className="desc">{item.desc}</p>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
      <RelationPage />
    </div>
  );
}

export default Byd;
