import React, { useEffect, useState } from 'react';
import Version1 from './Version1';
import Version2 from './Version2';
import Version3 from './Version3';

function PersonalInfo() {
  const [version, setVersion] = useState('4');
  const handleVersion = (event) => setVersion(event.target.value);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [version]);

  return (
    <div className="inner replace_page">
      <div className="visual" style={{ paddingBottom: '40px' }}>
        <h2 className="tlt">개인정보 처리방침</h2>
        <div className="form_wrap">
          <div className="input_wrap">
            <div className="select_default">
              <select id="input1" className="input_select" value={version} onChange={handleVersion}>
                <option value="4">2024.09.01 ~ 현재</option>
                <option value="3">2024.03.01 ~ 2024.08.31</option>
                <option value="2">2023.07.21 ~ 2024.02.29</option>
                <option value="1">2023.04.01 ~ 2023.07.20</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {version === '4' && (
        <div className="privacy ver_wrap">
          <p>
          ㈜GS글로벌(이하 ‘회사’라 한다)은 전기자동차 판매와 관련하여 처리하는 정보주체의 개인정보에 대해 개인정보관계 법령에 따라 보호하고,
          이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
          </p>

          <strong class="mlTit">[주요 개인정보 처리 표시 (라벨링)]</strong>
          <ul className="markLabelList">
            <li className="markLabel">
              <p className="labelTit">일반 개인정보 수집</p>
              <p className='lableIcon icon01'></p>
              <ul>
                <li>성명</li>
                <li>주소</li>
                <li>연락처</li>
                <li>이메일 등</li>
              </ul>
              <p className="etcText">※ 세부항목은 개인정보 처리방침 본문 확인</p>
            </li>
            
            <li className="markLabel">
              <p className="labelTit">개인정보 보유기간</p>
              <p className='lableIcon icon02'></p>
              <ul>
                <li>법령상 의무 보조 기간</li>
                <li>관련 목적 달성 또는 동의 철회시 까지</li>
              </ul>
              <p className="etcText">※ 일부 개인정보는 관련 법령에 따라 3년 이상 보관</p>
            </li>
            
            <li className="markLabel">
              <p className="labelTit">개인정보 처리 목적</p>
              <p className='lableIcon icon03'></p>
              <ul>
                <li>차량 구매 및 등록</li>
                <li>사전 예약</li>
                <li>시승 신청 등</li>
              </ul>
              <p className="etcText">※ 세부항목은 개인정보 처리방침 본문 확인 </p>
            </li>

            <li className="markLabel">
              <p className="labelTit">개인정보 파기</p>
              <p className='lableIcon icon04'></p>
              <ul>
                <li>목적 달성 시 법령에서 정한 방식으로 파기</li>
              </ul>
              <p className="etcText"> </p>
            </li>

            <li className="markLabel">
              <p className="labelTit">개인정보의 제공</p>
              <p className='lableIcon icon05'></p>
              <ul>
                <li>제3자 제공<br/>
                    <small>
                      - 딜러사 (A/S 진행)<br/>
                      - BYD (제조사, Warranty 진행)<br/>
                      - 국토교통부 및 관련기관<br/>
                      - 환경부 및 산하기관<br/>
                      - 국세청, 관세청 등
                    </small>
                </li>
              </ul>
              <p className="etcText"> </p>
            </li>

            <li className="markLabel">
              <p className="labelTit">개인정보의 고충처리부서</p>
              <p className='lableIcon icon06'></p>
              <ul>
                <li>개인정보 보호 책임자<br/>
                    <small>
                      - 문정길 팀장(IT기획팀)<br/>
                      - E-mail : jgmoon@gsgcorp.com<br/>
                      - 전화번호 : 02-2005-5333
                    </small>
                </li>
              </ul>
              <p className="etcText"> </p>
            </li>

          </ul>

          <h3>제1조 개인정보의 처리 목적 및 수집·이용</h3>
          <ol>
            <li>
            회사는 다음의 목적을 위하여 온라인, 지류, 대면등의 방법으로 개인정보를 수집하고 처리합니다. 
            처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호 관계 법령에 따라 별도의 동의를 
            받는 등 필요한 조치를 이행할 예정입니다.
              <div className="table_wrap">
                <div className="table_scroll_wrap">
                  <table>
                    <colgroup>
                      <col width="10%" />
                      <col width="10%" />
                      <col width="20%" />
                      <col width="30%" />
                      <col width="30%" />
                    </colgroup>
                    <caption className="blind">개인정보의 처리목적 항목 보유이용기간 표</caption>
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>필수/선택</th>
                        <th>목적</th>
                        <th>항목</th>
                        <th>보유/이용기간</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>차량 구매</td>
                        <td>필수</td>
                        <td>전기자동차 구매를 위한 행정 절차 진행을 위해</td>
                        <td>성명, 주소, 연락처(집 전화번호/휴대폰번호), 이메일, 구입 차량정보, 주민등록번호, 여권번호, 외국인등록번호, 운전면허번호</td>
                        <td>법령상 의무 보존 기간이 없는 한 동의한 날로부터 5년</td>
                      </tr>
                      <tr>
                        <td>사전 예약</td>
                        <td>필수</td>
                        <td>전기자동차 사전 예약 진행을 위해</td>
                        <td>성명, 연락처, 거주하는 지역</td>
                        <td>법령상 의무 보존 기간이 없는 한 동의한 날로부터 5년</td>
                      </tr>
                      <tr>
                        <td>시승 신청</td>
                        <td>필수</td>
                        <td>전기자동차 시승 및 상담 진행을 위해</td>
                        <td>성명, 연락처, 거주하는 지역, 관심 차종, 방문 일시</td>
                        <td>시승 및 상담 신청일로부터 5년</td>
                      </tr>
                      <tr>
                        <td>홍보 및 마케팅</td>
                        <td>선택</td>
                        <td>신규서비스(상품) 및 이벤트 안내를 위해</td>
                        <td>성명, 연락처, 이메일</td>
                        <td>정보주체로부터 동의받은 기간까지</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li>
            단, 상법 등 관련법령의 규정에 의하여 보존할 의무가 있는 경우 회사는 고객의 개인정보를 보관합니다. 
            이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 다음과 같습니다.
              <div>
                <p>① 회사의 상업장부와 영업에 관한 중요서류 및 전표 등에 관련된 정보 : 10년 - 중요서류 / 5 년 - 전표(상법)</p>
                <p>② 모든 거래에 관한 장부 및 증빙서류와 관련된 정보 : 5 년 (국세기본법, 법인세법) </p>
                <p>③ 계약 또는 청약철회 등에 관한 기록, 대금결제 및 재화 등의 공급에 관한 기록 : 5 년 (전자상거래 등에서의 소비자보호에 관한 법률)</p>
                <p>④ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년 (전자상거래 등에서의 소비자보호에 관한 법률) </p>
                <p>⑤ 장부와 교부한 세금계산서 또는 영수증 : 5 년 (부가가치세법) </p>
                <p>⑥ 「통신비밀보호법」에 따른 서비스이용기록, 접속로그, 접속 IP 정보 : 3 개월</p>
              </div>
            </li>
          </ol>

          <h3>제2조 개인정보의 처리 및 보유기간</h3>
          <ol>
            <li>
            회사는 법령에 따른 개인정보 처리 및 이용기간또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 처리 및 이용기간 내에서 개인정보를 처리·보유합니다.
            </li>
            <li>각각의 개인정보 처리 및 보유 기간은 제1조에 기재된 바와 같습니다.</li>
          </ol>

          <h3>제3조 개인정보의 제3자 제공</h3>
          <ol>
            <li>
            회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 
            개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. 
            </li>            
            <li>
            회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
              <div className="table_wrap">
                <div className="table_scroll_wrap">
                  <table>
                    <caption className="blind">개인정보 제공안내 표</caption>
                    <thead>
                      <tr>
                        <th>제공받는 자</th>
                        <th>제공 목적</th>
                        <th>개인정보 항목</th>
                        <th>보유 및 이용 기간</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>G&amp;B CV(Commercial Vehicle), 에이치에스오토모티브, 신아주이트럭, 아우토컴퍼니 자일자동차, GS mbiz 한국지엠여수서비스,
                        르노코리아 하남자동차공업사, 유케이모터스</td>
                        <td>보증수리 및 차량관리 서비스 제공</td>
                        <td>성명, 주소, 연락처(집 전화번호/ 휴대폰번호), 이메일, 구입 차량정보</td>
                        <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                      </tr>
                      <tr>
                        <td rowspan="2">
                        G&amp;B CV(Commercial Vehicle), 에이치에스오토모티브, 신아주이트럭, 위시모터스, 아우토컴퍼니
                        </td>
                        <td>전기자동차 사전 예약 진행</td>
                        <td>성명, 연락처(집 전화번호/핸드폰 번호), 거주하는 지역</td>
                        <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                      </tr>
                      <tr>
                        {/* <td></td> */}
                        <td>전기자동차 시승 및 상담 진행</td>
                        <td>성명, 연락처(집 전화번호/핸드폰 번호), 거주하는 지역, 관심 차종, 방문 일시</td>
                        <td>시승 및 상담 신청일로부터 1년 혹은 동의 철회 시까지</td>
                      </tr>
                      <tr>
                        <td>BYD KOREA</td>
                        <td>차량 Warranty 서비스 제공</td>
                        <td>차량번호, 차대번호, A/S 관련 이력</td>
                        <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                      </tr>
                      <tr>
                        <td>하나캐피탈, KB캐피탈</td>
                        <td>차량 할부 금융서비스 제공</td>
                        <td>성명, 주소, 연락처(집 전화번호/ 휴대폰번호), 이메일, 구입 차량정보</td>
                        <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                      </tr>
                      <tr>
                        <td>LG유플러스</td>
                        <td>M2M 서비스* 제공</td>
                        <td>성명,연락처(휴대폰번호), 이메일,구입차량정보 관련 이력</td>
                        <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                      </tr>
                    </tbody>
                  </table>
                  <small className='smallFont'>*M2M 서비스: LTE라우터(LTE 신호를 유무선 인터넷으로 변환해주는 장비)를 이용해 위치, 장소 제약 없이 인터넷을 사용할 수 있는 서비스</small>
                </div>
              </div>
            </li>
            <li>
            회사는 개인정보 보호법 제17조 제1항 제2호에 따라 법령상 의무 준수 등을 위하여 정보주체의 동의 없이 관계 기관에 개인정보를 제공할 수 있습니다.
            또한, 본 항에 명시되지 않은 사항에 대해서도 법률의 특별한 규정이 있거나 법령상 의무 준수를 위한 경우에는 정보주체의 동의 없이 개인정보를 제공할 수 있습니다.
              <div className="table_wrap">
                <div className="table_scroll_wrap">
                  <table>
                    <caption className="blind">개인정보 제공 안내표</caption>
                    <thead>
                      <tr>
                        <th>제공 기관</th>
                        <th>제공 사유</th>
                        <th>제공 항목</th>
                        <th>근거 법령</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>환경부 및 소속기관</td>
                        <td>결함확인검사</td>
                        <td>이름, 주소, 전화번호, 우편번호, 차종·형식, 차대번호</td>
                        <td>
                        대기환경보전법 제51조, 같은 법 시행규칙 제72조 제5항, 자동차 배출가스 결함확인검사용 자동차 선정 등에 관한 규정 제2조 제2항
                        </td>
                      </tr>
                      <tr>
                        <td>환경부, 국토교통부, 자동차안전연구원</td>
                        <td>리콜통지문 발송 (차량 결함 확인시)</td>
                        <td>차대번호</td>
                        <td>
                        대기환경보전법 제51조,같은 법 시행규칙 제75조 제6호, 자동차관리법 제31조 제1항, 
                        같은 법 시행규칙 제41조 제2항 제7호,자동차 및 자동차부품의 인증 및 조사 등에 관한 규정 제8조의2
                        </td>
                      </tr>
                      <tr>
                        <td>국토교통부 및 관련기관</td>
                        <td>자동차 신규등록</td>
                        <td>이름,주소,주민등록번호,차종·형식, 차대번호</td>
                        <td>자동차관리법 제8조 제3항, 자동차등록규칙 제4조 제1항</td>
                      </tr>
                      <tr>
                        <td>국세청</td>
                        <td>부가가치세 등 각종 세금의 신고</td>
                        <td>이름, 주소, 연락처, 주민등록번호</td>
                        <td>부가가치세법 제32조 제1항, 같은 법 시행령 제67조 제4항</td>
                      </tr>
                      <tr>
                        <td>한국소비자원 및 위탁단체(소비자단체)</td>
                        <td>소비자 상담 및 구제 </td>
                        <td>이름, 주소, 연락처, 차량정보, 차량정비이력</td>
                        <td>소비자기본법 제18조 제2항, 제78조 제1항</td>
                      </tr>
                      {/* <tr>
              <td>신한카드</td>
              <td>차량 할부 금융서비스 제공</td>
              <td>성명, 주소, 연락처(집 전화번호/ 휴대폰번호), 이메일, 구입 차량정보</td>
              <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
            </tr> */}
                      <tr>
                        <td rowSpan={2}>지방자치단체</td>
                        <td>보조금 지원</td>
                        <td>자동차등록증 작성을 위한 정보 일체</td>
                        <td>
                        보조금 관리에 관한 법률 제26조의3 제1항 제8호,같은 법 시행령 제10조의5 제1항
                        </td>
                      </tr>
                      <tr>
                        <td>소비자 상담 및 구제</td>
                        <td>이름, 주소, 연락처, 차량정보, 차량정비이력</td>
                        <td>소비자기본법 제18조 제4항</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
          </ol>

          <h3>제4조 개인정보처리의 위탁</h3>
          <ol>
            <li>
            회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
              <div className="table_wrap">
                <div className="table_scroll_wrap">
                  <table>
                    <caption className="blind">개인정보처리 위탁 안내표</caption>
                    <thead>
                      <tr>
                        <th>위탁 업무</th>
                        <th>수탁사</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">보증수리 및 차량관리 서비스 제공</td>
                        <td>
                        G&B CV(Commercial Vehicle), 자일자동차, GS mbiz
                        </td>
                      </tr>
                      <tr>
                        {/* <td></td> */}
                        <td>[재위탁]G&amp;B automobile</td>
                      </tr>
                      <tr>
                        <td>IT시스템 운영 및 유지보수</td>
                        <td>NEXOL WIZVIL</td>
                      </tr>
                      <tr>
                        <td>문의사항 응대</td>
                        <td>U-BASE</td>
                      </tr>
                      <tr>
                        <td>긴급 출동</td>
                        <td>GS mbiz</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li>
            회사는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적•관리적 보호조치, 재위탁 제한, 
            수탁자에 대한 관리•감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
            </li>
            <li>
            위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            </li>
          </ol>

          <h3>제5조 정보주체의 권리·의무 및 행사방법</h3>
          <ol>
            <li>
            정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
              <div>
                <p>① 개인정보 열람요구</p>
                <p>② 오류 등이 있을 경우 개인정보 정정 요구</p>
                <p>③ 개인정보 삭제요구</p>
                <p>④ 개인정보 처리정지 요구</p>
              </div>
            </li>
            <li>
            제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
            </li>
            <li>
            정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
            </li>
            <li>
            제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 
            제출하셔야 합니다.
            </li>
            <li>
            정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다.
            </li>
          </ol>

          <h3>제6조 처리하는 개인정보 항목</h3>
          <ol>
            <li>회사는 제1조에 기재된 바와 같은 개인정보 항목을 처리하고 있습니다.</li>
            <li>
              주민등록번호는 다음 각 호의 사항에 해당하는 경우에 한하여 처리합니다.
              <div>
                <p>
                  ① 원천징수 등 법률·대통령령·국회규칙·대법원규칙·헌법재판소규칙·중앙선거관리위원회규칙 및 감사원규칙에서 구체적으로 주민등록번호의 처리를 요구하거나 허용한 경우
                </p>
                <p>
                  ② 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 명백히 필요하다고 인정되는 경우
                </p>
                <p>③ 주민등록번호 처리가 불가피한 경우로서 보호위원회가 고시로 정하는 경우</p>
              </div>

            </li>
            <li>
            인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
              <div>IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등</div>
            </li>
          </ol>

          <h3>제7조 개인정보의 파기</h3>
          <ol>
            <li>
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
            </li>
            <li>
            정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 
            보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            </li>
            <li>
              개인정보 파기의 절차 및 방법은 다음과 같습니다.
              <div>
                <p>
                  ① 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                </p>
                <p>
                  ② 파기방법 : 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 
                  종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
                </p>
              </div>
            </li>
          </ol>

          <h3>제8조 개인정보의 안전성 확보조치</h3>
          <ol>
            <li>
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
              <div>
                <p>① 관리적 조치: 내부관리계획 수립•시행, 정기적 직원 교육 등</p>
                <p>
                  ② 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
                </p>
                <p>③ 물리적 조치: 사무실 등의 접근통제</p>
              </div>
            </li>
          </ol>

          <h3>제9조 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</h3>
          <ol>
            <li>
            회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
            </li>
            <li>
            쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 
            하드디스크에 저장되기도 합니다.
              <div>
                <p>
                  ① 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                </p>
                <p>
                  ② 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
                </p>
                <p>③ 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
              </div>
            </li>
          </ol>

          <h3>제10조 개인정보 보호책임자</h3>
          <ol>
            <li>
           	회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 열람청구, 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자 
            등을 지정하고 있습니다.
              <div>
                <p>
                  · 개인정보 보호책임자: 문정길 팀장(IT기획팀) / 연락처: 02-2005-5333	/ E-mail: jgmoon@gsgcorp.com
                </p>
                <p>
                  · 개인정보 보호담당자: 최정우매니저(IT기획팀) / 연락처: 02-2005-5337 / E-mail: rocknjwc@gsgcorp.com
                </p>
                <p>
                  · 개인정보 보호담당자: 송석매니저(사업지원팀) / 연락처: 02-2005-5475 / E-mail: songseok@gsgcorp.com
                </p>
              </div>
            </li>
            <li>
            정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 
            있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </li>
          </ol>

          <h3>제11조 권익침해 구제방법</h3>
          <p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</p>
          <p>&lt;아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 
            자세한 도움이 필요하시면 문의하여 주시기 바랍니다.&gt;</p>          


          <div style={{ marginLeft: '16px' }}>
            <p style={{ margin: '0 0 .25rem 0' }}>
              ① 개인정보 침해신고센터 (한국인터넷진흥원 운영)
            </p>
            <p>· 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
            <p>· 홈페이지 : privacy.kisa.or.kr</p>
            <p>· 전화 : (국번없이) 118</p>
            <p>· 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터</p>
            <p style={{ margin: '.25rem 0' }}>② 개인정보 분쟁조정위원회</p>
            <p>· 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</p>
            <p>· 홈페이지 : www.kopico.go.kr</p>
            <p>· 전화 : (국번없이) 1833-6972</p>
            <p>· 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</p>
            <p>· 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</p>
            <p>· 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</p>
          </div>

          <h3>제12조 개인정보 처리방침 변경</h3>
          <ol>
            <li>이 개인정보 처리방침은 2024. 9. 1.부터 적용됩니다.</li>
            <li>
              이전의 개인정보 처리방침은{' '}
              <button
                onClick={() => setVersion('3')}
                style={{ textDecoration: 'underline', color: '#4876ee' }}
              >
                본 링크
              </button>
              를 클릭하여 확인하실 수 있습니다.
            </li>
          </ol>
        </div>
      )}
      {version === '3' && (
        <div className="privacy ver_wrap">
           <Version3 setVersion={setVersion} />
        </div>
      )}
      {version === '2' && (
        <div className="privacy ver_wrap">
           <Version2 setVersion={setVersion} />
        </div>
      )}
      {version === '1' && (
        <div className="privacy ver_wrap">
          <Version1 />
        </div>
      )}
    </div>
  );
}

export default PersonalInfo;
