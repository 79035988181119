import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { actSetCurrentNo, getPartsInfoList } from 'utile/slice/searchBoardSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import useSearchFormHook from '../../../../utile/hook/useSearchFormHook';

import PaginationList from '../../../common/pagination/BoardPagination';
import PartList from './PartList';
import SearchBar from './SearchBar';

function PartsPrice() {
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const [pageNo, setPageNo] = useState(state ? state : 1);
    const Page = useAppSelector(state => state.searchBoard.paginationInfo);
    const currentPageNo = useAppSelector(state => state.searchBoard.currentPageNo);
    const { searchForm } = useAppSelector((state) => state.tempForm);
    const { onSearch } = useSearchFormHook();

    useEffect(() => {
        dispatch(actSetCurrentNo(1));
    }, []);

    useEffect(() => {
        //console.log("currentPageNocurrentPageNocurrentPageNocurrentPageNocurrentPageNo====>", currentPageNo)
        //dispatch(onSearch(pageNo));
        //dispatch(getPartsInfoList({currentPageNo: pageNo}))
        var searchData = {};
        searchData.form = searchForm;
        searchData.currentPageNo = currentPageNo;
        dispatch(getPartsInfoList(searchData));
        // setPageNo(Page.currentPageNo)
    }, [currentPageNo]);

    const onChangePageNo = (pageNum) => {
        //setPageNo(pageNum);
        dispatch(actSetCurrentNo(pageNum));
    }

    function showModal(e) {
        const inputWrap = e.target.closest('.center_cont').querySelector('.input_layout');
        inputWrap.classList.add('active');
    }

    return (
        <>
            <div className="center_cont pdt0">
                <button type="button" className="search_btn btn_txt mo_view" onClick={showModal}>
                    <i className="ico_20_prev_board"></i>
                    <span>검색</span>
                </button>
                <SearchBar />
            </div>

            <div className="table_wrap">
                <PartList />
                <PaginationList paginationData={Page} currentNum={currentPageNo} setCurrentNum={onChangePageNo} />
                <ul className="info">
                    <li className="sub">
                        ※ 자동차관리법 제32조의2 1항의4에 따라 부품가격정보를 제공합니다.
                    </li>
                    <li className="sub">
                        ※ 부품가격은 수시로 변동될 수 있으며, 실제 가격은 구매시점에 따라 다릅니다.
                    </li>
                </ul>
            </div>
        </>

    );
}

export default PartsPrice;
