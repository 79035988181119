// components\pages\model\features\T4KCold.js
import React from 'react';
import LazyLoad from 'react-lazyload';
import { Swiper, SwiperSlide } from 'swiper/react';

function T4KCold() {


  return (
    <div className="features_tab">
      <section className="visual_title">
        <div className="visual_tit_img t4k_cold">
        </div>
        <div className="inner_box">
          <h2 className="title center" data-trigger>T4K COLD KEEPER<span>(냉동탑차)</span></h2>
          <p className='desc' data-trigger>올인원 컨트롤러를 적용, 보조 배터리를 추가할 필요가 없어 비용을 절감하고<br />
            빠른 충전이 가능한 동시에 긴 주행거리와 넉넉한 적재공간을 제공합니다.</p>
        </div>
      </section>

      <section className="sectionDiv cold_feature">
        <div className="inner_box">
          <div className='title_wrap'>
            <h2 className="title" data-trigger>냉동탑차 특장점</h2>
          </div>
          <div className="features_slider" data-trigger>
            <LazyLoad>
              <Swiper
                className="features_swiper"
                slidesPerView="auto"
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide className="section_features_slide">
                  <h3>압도적인 냉동 성능</h3>
                  <p>
                    대용량 배터리를 사용해 운행시간 대비 전력 소모는 적고, 70분 만에 영하 20도로 떨어지는 등 강력한 냉동 성능을 자랑합니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="section_features_slide">
                  <h3>동급 최장 주행거리</h3>
                  <p>
                    상온 <em>205</em>㎞ / 저온 <em>164</em>㎞<br />
                    동급 최장 주행거리를 자랑합니다.<br /><small>(환경부공인인증거리 기준)</small>
                  </p>
                </SwiperSlide>
                <SwiperSlide className="section_features_slide">
                  <h3>업계 최대 적재공간</h3>
                  <p>
                    적재함 내측 <em>2,850</em>x<em>1,630</em>x<em>1,650</em>㎜ / 용적량 <em>7.67</em>㎥ 의
                    높고 넓은 공간으로 실내 이동 및 작업이 용이합니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="section_features_slide">
                  <h3>단 하나의 커넥터로 편리하고 빠른 충전</h3>
                  <p>
                    차량과 냉동기의 구동 배터리 일원화로 1개의 커넥터를 이용해 배터리를 충전하기에 빠르고 효율적입니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="section_features_slide">
                  <h3>국내 최초 전력 에너지 외부로 송출 가능</h3>
                  <p>
                    국내 출시 상용차 최초로 전기차의 전력 에너지를 외부로 보내는 기술(V2L)을 탑재, 전용 커넥터를 활용해 실외에서도 220v 전력을 사용할 수 있습니다.
                  </p>
                </SwiperSlide>
              </Swiper>
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className="sectionDiv byd_controller">
        <div className="inner_box">
          <div className="controller_txt title_wrap">
            <h2 className="title" data-trigger>보다 편리한 충전</h2>
            <em class="tit_s" data-trigger>BYD ALL-IN-ONE CONTROLLER</em>
            <p data-trigger>안정성을 강화해 폭발 및 발화 위험이 없는 BYD 차세대 배터리와 냉동기 컴프레서를 연결하는 전원 분배 장치 ‘올인원 컨트롤러’를 적용해 무게를 경량화하고 시스템을 단순화시켰습니다.</p>
          </div>
          <div className="controller_img_wrap" data-trigger>
            <div class="controller_img"></div>
          </div>
        </div>
      </section>

      <section className="sectionDiv cold_performance">
        <div className="inner_box">
          <div className="title_wrap cold_per_title">
            <h2 className="title" data-trigger>압도적인 냉동 성능</h2>
            <em class="tit_s" data-trigger>냉동 성능 검증 테스트 완료</em>
            <p data-trigger>냉동기 업체에서 실시하는 T4K 냉동기 기능 및 성능 점검 테스트를진행하고, 검증 작업을 완료했습니다. (풀다운, 재상, 보냉 성능 등)</p>
          </div>
          <div className='cold_per_desc'>
            <div className="title_wrap">
              <em class="tit_s border_line" data-trigger>소요시간 및 소비전력</em>
            </div>
            <div className='cold_per_box first' data-trigger>
              <div className="descBox">
                <p><em>30.2℃</em></p>
                <p class="arw">도달 시</p>
                <p><em>-18℃</em></p>
              </div>
              <div className="descBox">
                <p className='time'>
                  <span>일반출력 풀다운 <br /><em>69</em>분</span>
                </p>
                <p className="energy">
                  <span>소비전력 <br /><em>2.057</em>kw</span>
                </p>
              </div>
              <p className='addText'>*넉넉한 배터리 용량으로 풀다운 출력 시에도 충분한 냉동기 성능 발휘</p>
            </div>
            <div className="title_wrap">
              <em class="tit_s border_line" data-trigger>운행시간별 소비전력</em>
            </div>
            <div className='cold_per_box' data-trigger>
              <div className="descBox">
                <p className='temp'><span> <em>-18℃</em> 유지시</span></p>
              </div>
              <div className="descBox">
                <p className="energy01">
                  <span>8시간 총 소비전력 <br /><em>8.824</em>kw</span>
                </p>
                <p className="energy01">
                  <span>10시간 총 소비전력 <br /><em>10.735</em>kw</span>
                </p>
              </div>
              <p className='addText'>*상온 챔버에서 외부 온도를 영상 30도로 설정했을 때, 내부 온도를 영하 18도까지 내리는데 소모된 소비전력</p>
            </div>

          </div>
        </div>
      </section>

      <section className="sectionDiv spec_section">
        <div className='inner_box'>
          <div className="title_wrap">
            <h2 className="title center" data-trigger>냉동탑차 제원 및 가격</h2>
          </div>
          <div className='spec_wrap cold_spec'>
            <div className='specImgWrap'>
              <div className='specImg'></div>
            </div>
            <div className='specTableWrap table_wrap'>
              <p className='specTitle'>주요 제원</p>
              <div className='table_scroll_wrap'>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan="2" colSpan="3">구분</th>
                      <th rowSpan="2">최대 적재량 (kg)</th>
                      <th colSpan="3">차량 제원 (mm)</th>
                      <th colSpan="3">냉동탑 제원 (mm)</th>
                    </tr>
                    <tr>
                      <th>전장</th>
                      <th>전폭</th>
                      <th>전고</th>
                      <th>전장</th>
                      <th>전폭</th>
                      <th>전고</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="2">T4K 1톤</td>
                      <td rowSpan="2">와이드캡</td>
                      <td>저상</td>
                      <td>800</td>
                      <td>5,370</td>
                      <td>1,770</td>
                      <td>2,420</td>
                      <td>2,850</td>
                      <td>1,630</td>
                      <td>1,450</td>
                    </tr>
                    <tr>
                      <td>표준</td>
                      <td>800</td>
                      <td>5,370</td>
                      <td>1,770</td>
                      <td>2,620</td>
                      <td>2,850</td>
                      <td>1,630</td>
                      <td>1,650</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className='specTitle'>냉동기 주요 제원</p>
              <div className='table_scroll_wrap'>
                <table>
                  <thead>
                    <tr>
                      <th colSpan='2'>구분</th>
                      <th>DK-eT1300 냉동기</th>
                    </tr>
                    <tr>
                      <th colSpan='2'>구조</th>
                      <th>차량 전용 배터리 구동 방식</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan='2'>사이즈(MM)</td>
                      <td>중발기</td>
                      <td>1,076 X 873 X 196</td>
                    </tr>
                    <tr>
                      <td>응축기</td>
                      <td>1,290 X 507 X 250</td>
                    </tr>
                    <tr>
                      <td rowSpan='2'>팬/모터</td>
                      <td>중발기</td>
                      <td>프로펠러 팬 Φ 255</td>
                    </tr>
                    <tr>
                      <td>응축기</td>
                      <td>방수팬 Φ 305</td>
                    </tr>
                    <tr>
                      <td rowSpan='2'>발레오 컴프레서</td>
                      <td>용량</td>
                      <td>34CC</td>
                    </tr>
                    <tr>
                      <td>속도</td>
                      <td>600~9000 RPM</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='table_scroll_wrap'>
                <table>
                  <thead>
                    <tr>
                      <th>차종</th>
                      <th>세부차종</th>
                      <th>사양</th>
                      <th>장(mm)</th>
                      <th>폭(mm)</th>
                      <th>고(mm)</th>
                      <th>판매가 (단위:천원/VAT포함)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>냉동탑차</td>
                      <td>기본</td>
                      <td>기본</td>
                      <td>2,850</td>
                      <td>1,630</td>
                      <td>1,650</td>
                      <td>64,900</td>
                    </tr>
                    <tr>
                      <td colSpan="7">선택사항* 냉동탑 스포일러 무상 지급</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='btmText'>※ 당사는 차량 外 냉동탑차, 내장탑, 윙바디 등 개별 특장 차량을 출시, 제작, 납품하지 않으며 그에 관한 의무를 부담하지 아니합니다.
             상세한 사항은 각 딜러사 및 개별 특장사에 문의 부탁드립니다. 
          </div>
        </div>
      </section>

    </div>
  );
}

export default T4KCold;
