import { useForm } from 'react-hook-form';
import { actShowAPopUp } from '../slice/alartPopUpSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { postBoardForm } from '../slice/postBoardFormSlice';
import { actSaveCustomerForm } from '../slice/tempFormSlice';
import { actShowVerifyBoard, actHideVerifyBoard } from '../slice/verifyMessageSlice';
const useVerifyMessageBoardFormHook = (alartInfo = {}, reqUrl) => {
  const dispatch = useAppDispatch();

  const { resultCode } = useAppSelector((state) => state.boardForm);

  const { register, handleSubmit, reset, setValue, getValues } = useForm();

  const onSubmit = (saveform) => {
    const { bbsTitNm, bbsSeq, bbsUserNm, bbsUserContact, bbsUserMail, password, bbsCont, secretYn } = saveform;
    const form = {bbsTitNm, bbsSeq, bbsUserNm, bbsUserContact, bbsUserMail, password, bbsCont, secretYn};
    const formData = { form, reqUrl };

    dispatch(postBoardForm(formData));
    onResult(alartInfo);
  };

  const onResult = (alartInfo) => {
    const { fail } = alartInfo;

    if (window.location.pathname === '/qna') {
      if (resultCode === "00" || resultCode === "03") {
        reset();

        dispatch(actHideVerifyBoard());
        dispatch(actSaveCustomerForm({}));
      } else {
        dispatch(actShowAPopUp(fail));
        dispatch(actShowVerifyBoard(false));
      }
    }
  };

  const onError = (error) => {
    const requiredErr = Object.entries(error)
    .map((v) => {
      return v[1];
    })
    .filter((v) => {
      return ['required', 'pattern'].includes(v.type);
    });

    if (requiredErr.length) {
      dispatch(
        actShowAPopUp({
          message: requiredErr[0].message,
          cntBtn: 1,
          img: '',
          btnName: {},
        }),
      );
    }
  };

  return { register, handleSubmit, onSubmit, onError, setValue, getValues, reset };
};

export default useVerifyMessageBoardFormHook;
