import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function PartsLogistics() { 

  const [activeIndex] = useState(0);
  
  useEffect(() => {
    const handleGsap = () => {
      const targets = document.querySelectorAll('[data-trigger]');
      if (!targets.length) return;
      
      ScrollTrigger.defaults({
        end: 'center 55%',
      });

      const createAnimation = (element, startPosition, duration) => {
        return gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: startPosition,
          },
        }).fromTo(
          element,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration }
        );
      };

      ScrollTrigger.matchMedia({
        '(min-width: 769px)': () => {
          targets.forEach(el => createAnimation(el, 'bottom bottom', 1.4));
        },
        // Mobile
        '(max-width: 768px)': () => {
          targets.forEach(el => createAnimation(el, 'center bottom', 0.8));
        }
      });
    };

    handleGsap();

    // Cleanup
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [activeIndex]);


  return (
    <div className="PartsLogistics">
      <div className="title_wrap" data-trigger>
        <strong className="tlt">고객 편의성을 최우선으로 합니다. </strong>
      </div>
      <div className='card_wrap'>
        <ul className='card'>
            <li className='item' data-trigger>
                <strong className='tlttle'>국내 보유 부품</strong>
                <p className='desc'>국내 보유 부품은 익일출고를 원칙으로 고객이 필요한 부품을 신속하게 공급합니다.</p>
            </li>
            <li className='item' data-trigger>
                <strong className='tlttle'>긴급 수리 부품</strong>
                <p className='desc'>긴급 수리를 위한 부품은 퀵, 용차, 버스, KTX, 항공 등 가장 빠른 방법으로 현장으로 공급합니다.</p>
            </li>
            {/* <li className='item'>
                <strong className='tlttle'>인천 송도 부품센터</strong>
                <p className='desc'>부품의 안정적인 공급을 위해 인천 송도에 550평 규모의 부품센터 운영</p>
                <ul className='partsCenterImg'>
                    <li className='imgBox'></li>
                    <li className='imgBox'></li>
                    <li className='imgBox'></li>
                    <li className='imgBox'></li>
                </ul>
            </li> */}
        </ul>   
      </div>

      <div className='partsCenterMovie'>
        <div className='title_wrap' data-trigger>
            <strong className="tlt">인천 송도 부품센터</strong>
            <p>각 서비스센터 및 고객에게 안정적인 부품 공급을 위해 인천송도 800평 규모의 A/S부품센터 운영<br/>
            (2025년 추가 확장예정)
            </p>
        </div>
        <div className='partsCenterVideo'>
          <div className='videoBox'>
            <video autoPlay loop muted width="100%">
              <source src="/media/sinl.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
         </div> 
         
      </div>

      <div className='partsCenterWrap'>
        <div className='partsCenter' data-trigger>
          <div className='partsCenterImg'></div>
          <div className='partsCenterInfo'>
            <strong>신속하고 안정적인 부품 공급</strong>
            <p>시스템을 통해 주문된 부품은 당일발송, 익일 전국 A/S딜러 도착으로 운영</p>
          </div>
        </div> 
        <div className='partsCenter' data-trigger>
          <div className='partsCenterImg'></div>
          <div className='partsCenterInfo'>
            <strong>재고 보유 품목</strong>
            <p>사고수리, 보증수리, 소모품 등 신속수리를 위한 부품 전품목 보유</p>
          </div>
        </div>
        <div className='partsCenter' data-trigger>
          <div className='partsCenterImg'></div>
          <div className='partsCenterInfo'>
            <strong>부품 배송 운영</strong>
            <p>긴급 부품은 당일 딜러 도착,	일반 부품은 익일 딜러 도착 원칙으로 운영중 </p>
          </div>
        </div> 
      </div>


      <div className="cont_information partsBuyInquiry">
        <div className="title_wrap" data-trigger>
          <strong className="tlt">부품 구매 문의</strong>
          <p> 전국에 있는 AS센터를 통해 구매가 가능하며, 고객이 원하는 배송지로 부품 수령 가능</p>
        </div>          
        <div className="scroll_wrap pc_view" data-trigger>
          <Swiper
            className="scroll_cont1"
            slidesPerView="auto"
            scrollbar={{ draggable: true }}
            //autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            <SwiperSlide className="item">
              <div className="item_img step1"></div>
              <div className="tex_wrap">
                <strong className="tlt">01. 구매문의</strong>
                <p className="desc">가까운 서비스센터에 구매문의</p>                
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step2"></div>
              <div className="tex_wrap">
                <strong className="tlt">02. 견적확인</strong>
                <p className="desc">서비스센터 또는 홈페이지 참조하여 부품 가격 및 최종 견적 확인</p>                
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step3"></div>
              <div className="tex_wrap">
                <strong className="tlt">03. 구매결정</strong>
                <p className="desc">상담 진행한 서비스센터에 구매 의사 전달</p>                
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step4"></div>
              <div className="tex_wrap">
                <strong className="tlt">04. 결제</strong>
                <p className="desc">서비스센터에서 직접 결제</p>                
              </div>
            </SwiperSlide>
            <SwiperSlide className="item">
              <div className="item_img step5"></div>
              <div className="tex_wrap">
                <strong className="tlt">05. 배송</strong>
                <p className="desc">고객이 원하는 배송지로 배송<br/>(단, 부품 종류에 따라 별도 협의 필요)</p>                
              </div>
            </SwiperSlide>            
          </Swiper>
        </div>

        {/* mobile */}
        <div className="scroll_wrap mo_view">
          <ul className="scroll_cont1">
            <li className="item" data-trigger>
              <div className="item_img step1"></div>
              <div className="tex_wrap">
                <strong className="tlt">01. 구매문의</strong>
                <p className="desc">가까운 서비스센터에 구매문의</p>                 
              </div>
            </li>
            <li className="item" data-trigger>
              <div className="item_img step2"></div>
              <div className="tex_wrap">
                <strong className="tlt">02. 견적확인</strong>
                <p className="desc">서비스센터 또는 홈페이지 참조하여 부품 가격 및 최종 견적 확인</p>                   
              </div>
            </li>
            <li className="item" data-trigger>
              <div className="item_img step3"></div>
              <div className="tex_wrap">
                <strong className="tlt">03. 구매결정</strong>
                <p className="desc">상담 진행한 서비스센터에 구매 의사 전달</p>                
              </div>
            </li>
            <li className="item" data-trigger>
              <div className="item_img step4"></div>
              <div className="tex_wrap">
                <strong className="tlt">04. 결제</strong>
                <p className="desc">서비스센터에서 직접 결제</p>                 
              </div>
            </li>
            <li className="item" data-trigger>
              <div className="item_img step5"></div>
              <div className="tex_wrap">
                <strong className="tlt">05. 배송</strong>
                <p className="desc">고객이 원하는 배송지로 배송<br/>(단, 부품 종류에 따라 별도 협의 필요)</p>            
              </div>
            </li>            
          </ul>
        </div>        
      </div>

    </div>
  );
}

export default PartsLogistics;
